// import axios from 'axios'

/**
 * Copy-paste from centra-storyblok-nuxt-shared/store/routes since that storyblok/fetchStory in this project returns
 * undefined instead o throwing an exception when it failed to fetch a story.
 */
export default {
  state() {
    return {
      /**
       * An array of { route: 'something/foo/bar', component: 'storyblok' } that
       * can be used as a local lookup cache when finding what should be rendered
       *
       * The component key simply references a registered vue component
       */
      routes: [
        {
          route: '/route/search',
          component: {
            component: 'search',
            id: null,
            url: 'search',
            data: null,
          },
        },
      ],
      currentRoute: null,
    }
  },
  mutations: {
    addRoute(state, payload) {
      state.routes = [payload].concat(
        state.routes.filter((route) => route.route !== payload.route)
      )
      state.currentRoute = payload
    },
  },
  actions: {
    /**
     * Call the frackend to get a component name which can be renderered depending
     * on an external route table (or simply 404)
     */
    async lookupRoute({ dispatch, commit, rootGetters, state }, routePath) {
      // The _storyblok code is spread all over the place until we
      // have a backend that can take care of it. So for now we mock
      // stuff
      if (this.$router.history.current.query._storyblok) {
        const story = await dispatch(
          'storyblok/fetchStory',
          {
            id: this.$router.history.current.query._storyblok,
            language: this.$router.history.current.query._storyblok_lang,
          },
          { root: true }
        )
        if (story === undefined) {
          console.warn('Failed to read storyblok story in storyblok edit mode.')
          return undefined
        }
        const data = {
          route: routePath,
          component: {
            component: 'storyblok',
            id: parseInt(this.$router.history.current.query._storyblok),
            url: routePath,
          },
        }
        commit('addRoute', data)
        return Promise.resolve({
          ...data.component,
          data: story,
        })
      }

      routePath = routePath.replace(/\/[\w-]+\/?/, '/')
      const route = '/route' + routePath

      const found = state.routes.find((x) => x.route === route)
      if (found) {
        commit('addRoute', found)

        let data
        if (found.component.component === 'storyblok') {
          data = rootGetters['storyblok/getStoryById'](found.component.id)
        } else if (found.component.component === 'product') {
          data = rootGetters['centra-product/getPdpProductById'](
            found.component.id
          )
        }
        return Promise.resolve({
          ...found.component,
          data,
        })
      }

      return this.$backendApi
        .get(route, {
          params: {
            language: rootGetters['frontend/currentLanguageCode'],
            country: rootGetters['frontend/currentCountryCode'],
            pricelist: rootGetters['frontend/pricelist'].pricelist,
            market: rootGetters['frontend/market'].market,
          },
        })
        .then((res) => {
          commit('addRoute', {
            route,
            component: { ...res.data, data: undefined },
          })
          if (res.data.component === 'storyblok') {
            commit(
              'storyblok/story',
              {
                id: parseInt(res.data.id),
                data: res.data.data,
              },
              { root: true }
            )
          } else if (res.data.component === 'product') {
            commit(
              'centra-product/products',
              { products: [res.data.data], dataType: 'pdpProducts' },
              { root: true }
            )
          }
          return res.data
        })
        .catch((err) => {
          // If 404 it means we should not render anything here, but are fine
          // to resolve an undefined result up
          if (err.response && err.response.status === 404) {
            return undefined
          }

          // Handle when there's an exception that's not a 404
          let message = ''
          if (err instanceof Error) {
            message = [err.toString(), err.stack?.toString()].join('\n')
          } else {
            message = err
          }
          console.error('Route failed without 404', message)
        })
    },

    getComponentByUrl: (state) => (url) =>
      state.routes.find((route) => route.route === url),
  },
}
