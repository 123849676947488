<template>
  <div class="badge" :class="type">
    {{ label }}
  </div>
</template>

<script>
export default {
  name: 'ProductBadge',
  props: {
    label: {
      type: String,
      required: true,
    },

    /**
     * Can be 'sale' etc.
     * Applies a class that can be used for styling
     */
    type: {
      type: String,
      required: false,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.badge {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.8rem;
  height: 6rem;
  background: $gold;
  text-transform: uppercase;
  @include p--small;
  margin: 0;
  font-size: 1rem;
  height: 2.2rem;
  line-height: 2.2rem;
  border-radius: 0.4rem;
  padding-top: 0.1rem;
  color: $black;
  background: $white;
  &.sale {
    width: 6rem;
    height: 6rem;
    @include p--large;
    font-weight: bold;
    border-radius: 100%;
    padding-top: 0.1rem;
    background: $gold;
    color: white;
  }
}

// Tablet (Portrait)
@media screen and (min-width: $tablet) {
}

// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
  .badge {
    height: 2.7rem;
    line-height: 2.7rem;
    font-size: 1.2rem;
    padding: 0 1.2rem;
    padding-top: 0.2rem;
  }
}

// Laptop
@media screen and (min-width: $laptop) {
  .badge {
    //font-size: 1.2rem;
    //line-height: 1.9em;
  }
}

// Desktop
@media screen and (min-width: $desktop) {
}
</style>
