<template>
  <div class="added-to-cart-popup di--4">
    <ModalHeader
      title-position="left"
      icon-position="right"
      @close="$store.dispatch('ui/added-to-cart/hideAddedToCart')"
    />
    <div class="content">
      <ResponsiveImage
        class="added-product-image"
        :mobile-src="product.mainImage"
        :desktop-src="product.mainImage"
        :lazy-load="false"
        alt="added product image"
        mobile-display-size="'34vw'"
        desktop-display-size="'34vw'"
      />
      <div class="added-text-wrapper">
        <h2 class="added-title h--small">{{ $t('Global_Added_To_Cart') }}</h2>
        <p>{{ product.name }}</p>
      </div>
      <div class="circle-loader" :class="{ 'load-complete': loadComplete }">
        <div class="checkmark draw" />
      </div>
    </div>
    <!--
      @TODO: @js: pass products into upsells
    -->
    <div v-if="upsells.length" class="upsell-products">
      <div class="upsell-title">
        {{ $t('Upsell_Complete_The_Style') }}
      </div>
      <div class="products">
        <ProductCard
          v-for="(upsellProduct, index) in upsells"
          :key="'upsell' + index"
          :position="index + 1"
          mobile-display-size="25vw"
          desktop-display-size="174px"
          :product="upsellProduct"
          :context-search="true"
        />
      </div>
    </div>
    <div class="buttons">
      <mp-link
        :to="$u('checkout')"
        class="button solid medium ui-green"
        @click.native="$emit('close')"
      >
        <span class="label">
          {{ $t('Global_To_Checkout') }}
        </span>
      </mp-link>
    </div>
  </div>
</template>

<script>
import ProductCard from '~/components/product-card/ProductCard'
import ModalHeader from '~/components/modal/modal-header/ModalHeader.vue'
export default {
  name: 'AddedToCartPopup',
  components: {
    ProductCard,
    ModalHeader,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    upsells: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      loadComplete: false,
    }
  },
  mounted() {
    setTimeout(() => {
      this.loadComplete = true
    }, 1000)
  },
}
</script>

<style lang="scss" scoped>
.added-to-cart-popup {
  top: var(--header-complete-height);
  background: $white;
  width: 100%;
  z-index: 10 !important;

  &::v-deep .modal-header {
    position: relative;
    height: 5.5rem;
    background-color: $white;
    border-bottom: none;
    z-index: 1;
  }
  .content {
    padding: 1.6rem;
    padding-top: 0;
    background: $white;
    position: relative;
    display: flex;
    ::v-deep .cart-item {
      background-color: transparent !important;
      padding: 0 !important;
      margin: 0;
      .remove,
      .change-quantity {
        display: none;
      }
    }
    .circle-loader {
      position: absolute;
      right: 1.6rem;
      top: 1.6rem;
    }

    .added-product-image {
      max-width: 40%;
    }

    .added-text-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: spacing('xsmall');

      .added-title {
        margin-bottom: 1rem;
      }
      > p {
        margin: 0;
        padding: 0;
      }
    }
  }
  .upsell-products {
    padding: 1.6rem;
    .upsell-title {
      grid-column: 1 / span 3;
      @include p--medium;
      margin-bottom: 1.6rem;
      text-align: center;
    }
    .products {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 0.8rem;
      .product {
        background: red;
        height: 250px;
      }
    }
  }
  .buttons {
    padding: 1.6rem;
    // display: grid;
    // grid-template-columns: 1fr 1fr;
    // grid-gap: 0.8rem;
    display: flex;
    box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.05);
    .button {
      flex-grow: 1;
      min-width: 0;
    }
  }
}
// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
  .added-to-cart-popup {
    right: 0;
    max-width: 50rem;
    transition-property: transform;

    &::v-deep .modal-header {
      padding-top: 2rem;
      height: 2rem;
    }

    .content {
      ::v-deep .cart-item {
        grid-template-columns: 1fr 5fr;
      }

      .added-product-image {
        max-width: 10rem;
      }
    }
    .upsell-products {
      //padding: 1.6rem 5.6rem;
      max-width: 460px;
      padding: 2.4rem 2.4rem;
      margin: 0 auto;
      .upsell-title {
        @include p--large;
      }
      .products {
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 2.4rem;
      }
    }
  }
}
</style>
