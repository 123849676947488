export default {
  state: () => ({
    account: null,
  }),
  mutations: {
    setAccount(state, account) {
      state.account = account
    },
  },
  actions: {
    async getAccount({ commit }) {
      await this.$backendApi
        .get('account')
        .then((response) => {
          commit('setAccount', response.data)
        })
        .catch((error) => {
          commit('setAccount', error.response.data)
        })
      window?.yotpoWidgetsContainer?.initWidgets &&
        window?.yotpoWidgetsContainer?.initWidgets()
    },
  },
}
