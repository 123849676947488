<template>
  <div
    class="footer"
    :class="[backgroundColorClass, foregroundColorClass, accentColorClass]">
    <Newsletter
      v-if="globalList"
      class="di di--3"
      :list="globalList"
      :foreground-color="foregroundColorName"
    />
    <div class="footer-wrapper di di--3">
      <div class="menus">
        <slot name="footer-menu" />
      </div>
      <div class="grid dc--4 dg--medium">
        <div class="country-wrap">
          <slot name="country-selector" />
        </div>
        <div class="salon hide-on-desktop">
          <h3 class="h--tiny">
            {{ $t('Footer_Salon_Heading') }}
          </h3>
          <mp-link
            :to="saloonLink && saloonLink.cached_url ? $u(saloonLink) : ''"
            class="button medium solid blue"
          >
            <span class="label">
              {{ $t('Footer_Salon_Button_Label') }}
            </span>
          </mp-link>
        </div>

        <div class="social-links">
          <h3>
            {{ $t('Footer_Social') }}
          </h3>
          <mp-link
            v-for="(socialMediaLink, i) in correctedSocialMediaLinks"
            :key="`social-links__link${i}${_uid}`"
            class="social-links__link"
            target="_blank"
            :to="
              socialMediaLink.link && socialMediaLink.link.cached_url
                ? $u(socialMediaLink.link)
                : ''
            "
          >
            <span
              v-if="socialMediaLink?.icon?.filename"
              class="social-links__link__icon"
              :style="[
                {maskImage: `url(${correctFileUrl(socialMediaLink.icon.filename)})`},
                {WebkitMaskImage: `url(${correctFileUrl(socialMediaLink.icon.filename)})`}
              ]"
            />
          </mp-link>
        </div>
      </div>
      <FooterLogos
        v-if="correctedFooterLogos && correctedFooterLogos.length"
        :logos="correctedFooterLogos"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Footer',
  props: {
    footer: {
      type: Object,
      required: false,
      default() {
        return {}
      },
    },
  },
  computed: {
    ...mapGetters({ getMenuById: 'storyblok/getMenuById' }),
    globalList() {
      let list
      const newsletterSettings =
        this.$store.getters['storyblok/settings']?.footerNewsletter
      if (newsletterSettings) {
        list = newsletterSettings.find(
          (x) =>
            x.countries.includes(
              this.$store.state.frontend.currentCountryCode
            ) || x.countries.includes('_all_countries')
        )?.list
      }
      return (
        list || this.$store.getters['storyblok/settings']?.footerNewsletterList
      ) // default
    },
    footerLogos() {
      return this.$store.state.storyblok.stories.find(
        (x) => x.data?.content?.component === 'CountryInformation'
      )?.data?.content?.footerLogos
    },
    socialMediaLinks() {
      return this.getMenuById('footer-menu')?.content?.socialMediaLinks
    },
    saloonLink() {
      return this.getMenuById('footer-menu')?.content?.saloonLink
    },
    settings() {
      return this.$store.getters['storyblok/settings']
    },
    backgroundColorClass() {
      return this.settings?.footerBackgroundColor ? 'footer-bgcolor--' + this.settings.footerBackgroundColor : ''
    },
    accentColorName() {
      return this.settings?.footerAccentColor
    },
    foregroundColorName() {
      return this.settings?.footerForegroundColor
    },
    foregroundColorClass() {
      return this.foregroundColorName ? 'footer-fgcolor--' + this.foregroundColorName : ''
    },
    accentColorClass() {
      return this.accentColorName ? 'footer-accent-color--' + this.accentColorName : ''
    },
    correctedSocialMediaLinks() {
      return this.socialMediaLinks?.map((link) => {
        return {
          ...link,
          icon: {
            ...link.icon,
            filename: this.correctFileUrl(link.icon.filename),
          },
        }
      })
    },
    correctedFooterLogos() {
      return this.footerLogos?.map((logo) => {
        return {
          ...logo,
          image: {
            ...logo.image,
            filename: this.correctFileUrl(logo.image.filename),
          },
        }
      })
    },
  },
  methods: {
    /**
     * Corrects the file URL to use the correct domain.
     * The "a.storyblok.com" domain doesn't set CORS headers on images.
     */
    correctFileUrl(url) {
      return url.replace('//a.storyblok.com', '//a2.storyblok.com');
    },
  },
}
</script>

<style lang="scss" scoped>
.footer {
  --footer-background-default: #{$blue-dark};
  --footer-foreground-default: #{$white};

  z-index: 11;
  background-color: var(--footer-background, var(--footer-background-default));
  color: var(--footer-foreground, var(--footer-foreground-default));

  .newsletter {
    padding: 4rem;
  }

  .footer-wrapper {
    .country-wrap {
      margin: 3.2rem 0;
      display: flex;
      justify-content: center;

      .country-selector {
        color: currentColor;
        display: inline-flex;
        justify-content: center;
        padding: 1.6rem 2.4rem;
        height: 4rem;

        ::v-deep .selected-country {
          .name {
            @include p--medium($font-base);
          }

          .chevron {
            margin-top: 1px;
            margin-left: 2.4rem;

            path {
              fill: currentColor;
            }
          }
        }
      }
    }

    .salon {
      text-align: center;
      padding: 0 4rem;
      margin: 3.2rem 0 1.6rem 0;

      h3 {
        color: currentColor;
      }

      .button {
        width: 100%;
        color: currentColor;
      }
    }
  
    .social-links {
      display: flex;
      justify-content: center;
      gap: 2.4rem;
      padding: 3.2rem;

      h3 {
        display: none;
      }

      .social-links__link {
        transition: background-color 0.05s cubic-bezier(0.4, 0, 0.2, 1) 0s;
        width: 4rem;
        height: 4rem;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
        cursor: pointer;
        border: none;
        outline: none;
        padding: 1rem;

        .social-links__link__icon {
          display: block;
          height: 100%;
          width: 100%;
          
          // Colorize SVG image
          mask-size: auto;
          -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
          mask-position: center;
          background-color: currentColor;
        }
      }
    }
  }
}

// Tablet (Portrait)
@media screen and (min-width: $tablet) {
  // ...
}

// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
  .footer {
    padding-top: 12rem;
    padding-bottom: 12rem;

    .footer-wrapper {
      padding-left: 4rem;
      padding-right: 4rem;

      .country-wrap {
        order: 2;
        margin: 0;
        flex-shrink: 1;
        flex-basis: 15%;
        justify-content: flex-start;
        display: flex;

        .country-selector {
          padding-left: 0;
          padding-right: 0;
        }
      }

      .salon {
        margin-top: 0;
        padding-left: 0;
        padding-right: 0;
        order: 3;
        text-align: right;
      }

      .social-links {
        padding: 0;
        order: 4;
        margin-left: -2.5rem;
        justify-content: flex-start;
      }
    }
  }
}

// Laptop
@media screen and (min-width: $laptop) {
  // ...
}
</style>
