export default {
  state () {
    return {
      countrySelectorOpen: false
    }
  },
  mutations: {
    showCountrySelector (state) {
      state.countrySelectorOpen = true
    },
    hideCountrySelector (state) {
      state.countrySelectorOpen = false
    }
  },
  actions: {
    showCountrySelector ({ dispatch, commit }) {
      dispatch('ui/close-all/closeAll', null, { root: true })
      dispatch('ui/overlay/showOverlay', 90, { root: true })
      commit('showCountrySelector')
    },
    hideCountrySelector ({ dispatch, commit }) {
      dispatch('ui/overlay/hideOverlay', null, { root: true })
      commit('hideCountrySelector')
    }
  }
}
