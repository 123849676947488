<template>
  <div class="sidebar-menu">
    <div class="sidebar-body level-1">
      <!--
        **
        * Level 1 Menu
        **
      -->
      <div class="main-menu-wrapper">
        <div class="main-menu">
          <CloseMenu
            class="close-menu"
            :visible="mobileSidebarOpen"
            :direction="'left'"
            @close="hideMobileSidebar"
          />

          <div class="menu-label">Shop</div>
          <div
            v-for="(item, index) in visibleMainMenuItems"
            :key="'navigation-' + index"
            class="item"
            :style="'--link-color:' + item.linkColor"
          >
            <!-- If item has a submenu -->
            <div
              v-if="item.subMenu.length"
              class="link"
              :class="{ open: expandedSubmenu === index }"
              @click="toggleSubmenu(index)"
            >
              <span class="label">
                {{ item.label }}
              </span>
              <ChevronDownIcon />
            </div>

            <!-- If item does not have a submenu -->
            <mp-link v-else :to="$u(item.link)" class="link">
              <span class="label">
                {{ item.label }}
              </span>
            </mp-link>

            <!-- Submenu -->
            <transition-expand>
              <div v-if="expandedSubmenu === index" class="submenu">
                <mp-link
                  v-for="(subMenuItem, subIndex) in item.subMenu"
                  :key="'submenuitem-' + subIndex"
                  :to="$u(subMenuItem.link)"
                  class="link"
                >
                  <span>
                    {{ subMenuItem.label }}
                  </span>
                </mp-link>
              </div>
            </transition-expand>
          </div>
        </div>

        <!--
        **
        * Secondary Menu
        **
      -->
        <div v-if="mainMenu" class="secondary-menu">
          <div
            v-for="(menu, index) in mainMenu.content.secondaryMenus"
            :key="'menu' + index"
          >
            <div v-if="menu.label" class="menu-label">
              <span>{{ menu.label }}</span>
            </div>
            <div
              v-for="(link, indexKey) in getSecondaryMenuItems(menu.menuItems)"
              :key="'child-' + indexKey"
              class="link"
            >
              <img
                v-if="link.icon.filename"
                :src="link.icon.filename"
                :alt="link.label"
                loading="lazy"
              />
              <mp-link
                :to="$u(link.link)"
                :target="link.targetBlank ? '_blank' : '_self'"
                :rel="link.targetBlank ? 'noreferrer nofollow' : false"
              >
                {{ link.label }}
              </mp-link>
            </div>
          </div>
        </div>
        <div class="bottom">
          <!--
          **
          * Country Selector
          **
        -->
          <div class="country-wrapper">
            <div class="label">
              <span> {{ $t('Global_Shopping_From') }}:&nbsp; </span>
            </div>
            <slot name="country-selector" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import ChevronDownIcon from '~/assets/icons/chevron-down-small.svg'
import CloseMenu from '~/components/close-menu/CloseMenu'

export default {
  name: 'SidebarMenu',
  components: {
    ChevronDownIcon,
    CloseMenu,
  },
  props: {
    mainMenu: {
      type: Object,
      required: false,
      default: null,
    },
    collections: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
    secondaryMenu: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
    shopByProduct: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
    shopByStyle: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
    shopByActivity: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      expandedSubmenu: null,
    }
  },
  computed: {
    ...mapGetters({
      countryCode: 'frontend/currentCountryCode',
    }),
    ...mapState('ui/mobile-sidebar', ['mobileSidebarOpen']),
    /**
     * Depending on the visibility setting (Mobile, Desktop, All, None),
     * only loop through visible items
     */
    visibleMainMenuItems() {
      return this.mainMenu?.content.topLevel
        .filter((it) => it.visibility === 'all' || it.visibility === 'mobile')
        .filter((it) =>
          it.countries?.length ? it.countries.includes(this.countryCode) : true
        )
    },
    visibleCollections() {
      return this.mainMenu?.content.collections
        .filter((it) => it.visibility === 'all' || it.visibility === 'mobile')
        .filter((it) =>
          it.countries?.length ? it.countries.includes(this.countryCode) : true
        )
    },
    /**
     * Join product items and style items together
     * in order to loop through them once
     */
    shopByMenu() {
      const output = []
      output.push(
        {
          label: this.mainMenu?.content.shopByProductLabel,
          children: this.mainMenu?.content.shopByProduct,
        },
        {
          label: this.mainMenu?.content.shopByStyleLabel,
          children: this.mainMenu?.content.shopByStyle,
        }
      )
      return output
    },
  },
  methods: {
    getSecondaryMenuItems(menuItems) {
      return menuItems.filter((it) =>
        it.countries?.length ? it.countries.includes(this.countryCode) : true
      )
    },
    toggleSubmenu(subMenu) {
      if (subMenu !== this.expandedSubmenu) {
        this.expandedSubmenu = subMenu
      } else {
        this.expandedSubmenu = null
      }
    },
    hideMobileSidebar() {
      this.$store.dispatch('ui/mobile-sidebar/hideMobileSidebar')
    },
  },
}
</script>

<style lang="scss" scoped>
.sidebar-menu {
  --side-padding: 2.4rem;
  --country-wrapper-height: 5.6rem;

  width: calc(100% - 5.6rem);
  height: calc((var(--vh, 1vh) * 100));
  max-width: 40rem;
  background-color: $white;
  color: $black;
  overflow: hidden;

  .sidebar-body {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    @include hide-scrollbars;
    z-index: 1;
    left: 0;
    background: $white;
    display: flex;
    flex-direction: column;
    transition: transform 0.6s cubic-bezier(0.5, 0, 0, 1);

    .main-menu-wrapper {
      position: relative;
      flex-grow: 1;
      padding-bottom: calc(
        var(--side-padding) * 2 + var(--country-wrapper-height)
      );
    }

    .menu-label {
      @include caps('large');
      color: $blue;
      margin-bottom: 1.2rem;
    }

    /**
     * Main Menu (Level 1)
     */
    .main-menu {
      margin: var(--side-padding) 0;
      padding: 0 var(--side-padding);

      .close-menu {
        margin-bottom: var(--side-padding);
      }

      .item {
        position: relative;
        display: block;
        .link {
          display: flex;
          justify-content: space-between;
          align-items: center;
          @include h--tiny;
          margin: 0;
          padding: 1.2rem 1.6rem;
          color: var(--link-color, $black);
          cursor: pointer;
          .label {
            display: flex;
            align-items: center;
            border-bottom: 2px solid transparent;
            .tag {
              margin-left: 1.6rem;
              position: unset;
            }
          }
          svg {
            height: 0.6rem;
            transition: transform 0.6s cubic-bezier(0.5, 0, 0, 1);
            path {
              fill: $black;
            }
          }
          &.open {
            .label {
              border-color: var(--link-color, $black);
            }
            svg {
              transform: rotate(180deg);
            }
          }
        }
        /**
         * Submenu (Level 2)
         */
        .submenu {
          .link {
            font-size: 1.5rem;
          }
        }
      }
    }

    /**
     * Secondary Menu
     */
    .secondary-menu {
      margin: var(--side-padding) 0 4rem 0;
      padding: 0 var(--side-padding);
      > * {
        .link {
          @include label('large');
          font-size: 1.4rem !important;
          padding: 0.8rem 0;
          display: flex;
          margin-left: 1.6rem;
          img {
            margin-right: 0.8rem;
          }
        }
      }
    }
    .bottom {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: var(--side-padding);
      background: $grey-light;
      /**
       * Country Selector
       */
      .country-wrapper {
        padding: 0 var(--side-padding);
        border-radius: 3px;
        background: $white;
        display: flex;
        height: var(--country-wrapper-height);
        .label {
          @include caps('small');
          margin: 0;
          display: flex;
          align-items: center;
          white-space: nowrap;
        }
        .country-selector {
          flex-grow: 1;
          display: flex;
          align-items: center;
          width: 100%;
          ::v-deep .selected-country {
            width: 100%;
            justify-content: flex-start;
            position: relative;
            .country {
              order: 2;
              margin: 0;
              margin-left: 0.4rem;
            }
            .name {
              @include caps('small');
              margin: 0;
              order: 1;
            }
            .chevron {
              height: 0.6rem;
              width: auto;
              order: 3;
              justify-self: flex-end;
              margin: 0;
              position: absolute;
              right: 0;
              path {
                fill: $black;
              }
            }
          }
        }
      }
    }
  }
}

// Tablet (Portrait)
@media screen and (min-width: $tablet) {
  .sidebar-menu {
  }
}

// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
  .sidebar-menu {
  }
}
</style>
