export default {
    state () {
      return {
        postType: null
      }
    },
    mutations: {
      setPostType (state, postType) {
        state.postType = postType
      },
      resetPostType (state) {
        state.postType = null
      }
    },
    actions: {
      setPostType ({ commit }, postType) {
        commit('setPostType', postType)
      },
      resetPostType ({ commit }) {
        commit('resetPostType')
      }
    }
  }
