export default function ({ store }) {
  if (process.client) {
    const { currentCountryCode } = store.state.frontend;
    let apiKey;
    switch (currentCountryCode) {
      case 'no':
        apiKey = 'f4ae05eef69d6fd476ea188b';
        break;
      case 'fi':
        apiKey = '3fa6e4f4caf64114bb0cf856';
        break;
      default:
        apiKey = '134d6e36c5e8929c812b2a95';
    }
    window.lipscoreInit = function () {
      lipscore.init({ apiKey: apiKey });
    };
  }
}
