import { debounce } from 'debounce'

export const sizeChartDelimiter = '-'

export const getProductPricelist = (store, product) => {
  return product?.markets?.[store.getters['frontend/currentMarketId']]
    ?.pricesByPricelist?.[store.getters['frontend/currentPricelistId']]
}

export const getProductPrice = (store, product, key) => {
  const pricelist = getProductPricelist(store, product)
  if (pricelist) {
    return pricelist[key]
  } else {
    return ''
  }
}

/**
 * This is where we do all the magic that formats the maximized centra format into something that we can
 * always work with. Add new things that you want to play with in here.
 */
export const transformProduct = (store, product) => {
  if (!product) {
    return null
  }
  if (product._transformed) {
    // Already transformed
    return product
  }

  // Get the localized value or the default one
  const getLocalizedValue = (key) => {
    const localeObject = product.localized?.[
      store.state.frontend.currentLanguageCode
    ]
      ? product.localized?.[store.state.frontend.currentLanguageCode]
      : product

    if (localeObject[key] !== undefined) {
      return localeObject[key]
    } else {
      return product[key]
    }
  }

  const sizeOptions = []
  Object.values(product.items).forEach((item) => {
    const nameParts = item.name.split(sizeChartDelimiter)
    nameParts.forEach((label, index) => {
      const itemData = {
        label: item.sizeLabel, // This means only 1 dimensional size tables
        originalLabel: label,
        value: item.item,
        quantity: item.stockByMarket[store.getters['frontend/currentMarketId']],
      }

      // If you have two-dimensional sizes
      if (nameParts.length > 1) {
        sizeOptions[index] = sizeOptions[index] || []
        sizeOptions[index].push(itemData)
      } else {
        sizeOptions.push(itemData)
      }
    })
  })

  let isOnesize = false
  if (sizeOptions.length === 1) {
    if (sizeOptions[0].label === '' || sizeOptions[0].label === '# Units') {
      isOnesize = true
    }
  }

  const itemsByMarket =
    product.markets[store.getters['frontend/currentMarketId']]
  const inStock = itemsByMarket?.stockOfAllItems !== 0

  const url = product.uri.replace(/^\//, '')

  // This removes the need of ugly ass shit in the templates
  const media = product.media
    .filter((it) => it.type === 'image')
    .filter((it) => it.type === 'image')
    .map((it) => it.sources)
    .filter((it) => it[product.productImageKey])
    .map((it) => it[product.productImageKey].url)
    .filter((m) => !/-feed-/.test(m))

  const discounted =
    getProductPrice(store, product, 'price') !==
    getProductPrice(store, product, 'priceBeforeDiscount')

  product._transformed = true

  // Right now i omit the actual centraProduct to keep things under control
  const returnProduct = {
    product: product.product, // OK centra
    productSku: product.productSku,
    variantName: product.variantName,
    sku: product.sku,
    badge: product.badge,
    brandName: product.brandName,
    silkProduct: product.silkProduct,
    price: getProductPrice(store, product, 'price'),
    priceAsNumber: getProductPrice(store, product, 'priceAsNumber'),
    priceBeforeDiscount: getProductPrice(store, product, 'priceBeforeDiscount'),
    lowestPrice: getProductPrice(store, product, 'lowestPrice'),
    discounted,
    discountPercent: getProductPrice(store, product, 'discountPercent'),
    name: getLocalizedValue('name'),
    silkProductName: getLocalizedValue('silkProductName'),
    description: getLocalizedValue('description').trim(),
    shortDescription: getLocalizedValue('excerpt').trim(),
    washingInstructions: getLocalizedValue('washing_instructions'),
    descriptionHeader: getLocalizedValue('description_header'),
    descriptionSubHeader: getLocalizedValue('description_sub_header'),
    fit: getLocalizedValue('fit'),
    color_swatch: getLocalizedValue('color_swatch'),
    volume: getLocalizedValue('volume'),
    color: getLocalizedValue('variantName')
      .toLowerCase()
      .replace(/\b\w/g, (l) => l.toUpperCase()),
    product_type: getLocalizedValue('folderName'),
    collection: product.collection,
    washing: product.washing,
    collectionName: product.collectionName,
    pattern: getLocalizedValue('pattern'),
    sustainabilityContent: product.sustainabilityContent,
    sizeOptions,
    isOnesize,
    inStock,
    items: product.items,
    url,
    videoUrl: product.video_url || null,
    videoImage: product.video_image || null,
    usp: getLocalizedValue('usp'),
    key_ingredients: product.key_ingredients,
    ingredient_list: product.ingredient_list,
    how_to: getLocalizedValue('how_to'),
    claims_benefits: getLocalizedValue('claims_benefits'),

    swatchProducts: product.swatchProducts,
    relatedProducts: product.relatedProducts,

    storyblokData: product.storyblokData,

    // We need the meta if supplied
    meta: {
      title: getLocalizedValue('metaTitle').trim(),
      description:
        getLocalizedValue('metaDescription')?.trim() ||
        getLocalizedValue('excerpt').trim(),
      keywords: getLocalizedValue('metaKeywords')?.trim() ?? '',
    },

    sticker: Object.values(product.sticker || {}).map((x) => x.name),

    // This allows us to hax business logic that we can rely on
    mainImage: media[0],
    thumbnailImage: media[0],
    media, // Always an array that can be iterated
    _transformed: true,
  }
  return returnProduct
}

// Global scope to keep the debounce function in memory between
// calls to the store
let lookupDebounced
let lookupDebouncedIds = []

/**
 * The store should for now keep a basic structure of the product tree and leave
 * all bigger and heavy lookups to route level lookups
 */
export default {
  namespaced: true,
  state() {
    return {
      plpProducts: [],
      pdpProducts: [],
      categoryProducts: {},
    }
  },
  mutations: {
    products(state, { products, dataType }) {
      const transformedProducts = products.map((product) =>
        transformProduct(this, product)
      )

      state[dataType] = transformedProducts.concat(
        state[dataType].filter((item) => {
          return !products.find((product) => product.product === item.product)
        })
      )
    },
    categoryProducts(state, { categoryId, productIds }) {
      state.categoryProducts[categoryId] = productIds
    },
  },
  actions: {
    lookupProduct({ commit }, id) {
      return this.$backendApi
        .get(`/products/${id}`)
        .then((response) => {
          commit('products', {
            products: response.data,
            dataType: 'pdpProducts',
          })
          return response.data
        })
        .catch((e) => {
          console.log(`Error in centra-product/lookupProduct - ${id} - ${e}`)
        })
    },
    lookupSwatchProduct(_, id) {
      return this.$backendApi
        .get(`/products/${id}`)
        .then((response) => {
          if (
            !response.data ||
            !Array.isArray(response.data) ||
            response.data.length === 0
          ) {
            throw new Error(`Invalid response data for product ID ${id}`)
          }
          try {
            return transformProduct(this, response.data[0])
          } catch (e) {
            console.error(
              `Error transforming product data for product ID ${id} - ${e}`
            )
            throw e // Re-throw the error to be caught by the outer catch block
          }
        })
        .catch((e) => {
          console.error(
            `Error in centra-product/lookupSwatchProduct - ${id} - ${e}`
          )
          throw e // Optionally re-throw the error if you want to handle it further up the call stack
        })
    },

    /**
     * Looks up products from centra
     *
     * Debounced because we might have multiple calls to this function
     * depending on how storyblok components are being used by the admins
     */
    lookupProducts(ctx, ids) {
      function __lookupProducts({ commit }, ids) {
        lookupDebouncedIds = []
        if (!ids && !ids?.length) {
          return null
        }

        // Remove already looked up, sort, and make unique
        ids = [...new Set(ids)]
        ids.sort()

        if (!ids.length) {
          return
        }

        const idsQuery = ids.join(',')
        return this.$backendApi
          .get(`/products/${idsQuery}`)
          .then((response) => {
            commit('products', {
              products: response.data,
              dataType: 'plpProducts',
            })
            return response.data
          })
          .catch((e) => {
            console.log(
              `Error in centra-product/lookupProducts - ${ids} - ${e}`
            )
          })
      }

      lookupDebouncedIds = lookupDebouncedIds.concat(ids)

      lookupDebounced =
        lookupDebounced || debounce(__lookupProducts.bind(this), 50)

      return lookupDebounced(ctx, lookupDebouncedIds)
    },

    lookupProductsByCategoryUri({ commit }, categoryUri) {
      return this.$backendApi
        .get(`/products/by-category-uri/${categoryUri}`)
        .then((response) => {
          commit('products', {
            products: response.data,
            dataType: 'pdpProducts',
          })
          commit('categoryProducts', {
            categoryId: categoryUri,
            productIds: response.data.map((p) => p.product),
          })
          return response.data
        })
        .catch((e) => {
          console.error(
            `Error in centra-product/lookupProductsByCategoryUri - ${categoryUri} - ${e}`
          )
        })
    },

    lookupProductsBySkus(_, skus) {
      return this.$backendApi
        .get(`/products/ids-by-skus/${skus}`)
        .then((response) => {
          return response.data
        })
        .catch((e) => {
          console.log(
            `Error in centra-product/lookupProductsBySkus - ${skus} - ${e}`
          )
        })
    },
  },
  getters: {
    getPlpProductById: (state) => (id) =>
      state.plpProducts.find((x) => x.product === id),
    getPdpProductById: (state) => (id) =>
      state.pdpProducts.find((x) => x.product === id),
    getProductsByCategoryUriOrId: (state) => (categoryUriOrId) => {
      return (
        state.categoryProducts[categoryUriOrId]
          ?.map((productId) =>
            state.pdpProducts.find((product) => product.product === productId)
          )
          .filter((product) => !!product) ?? []
      )
    },
  },
}
