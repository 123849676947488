<template>
  <div class="breadcrumbs">
    <ol
      vocab="https://schema.org/"
      typeof="BreadcrumbList"
    >
      <li
        v-for="crumb in breadcrumbs"
        :key="crumb.position"
        property="itemListElement"
        typeof="ListItem"
      >
        <mp-link
          :to="crumb.url"
          class="item"
          property="item"
          typeof="WebPage"
        >
          <span property="name">
            {{ crumb.name }}
          </span>
        </mp-link>
        <meta property="position" :content="crumb.position">
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  name: 'Breadcrumbs',
  props: {
    breadcrumbs: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.breadcrumbs {
  padding: 0.8rem 1.6rem .8rem 1.6rem;
  @include text-field('small');
  ol {
    li {
      display: inline-block;
      .item {
        border: none;
        @include link-underline;
        span {
          display: inline-block;
        }
        @media (hover: hover) {
          &:hover {
            border-bottom: 1px solid $black;
            color: $black;
          }
        }
      }
      &:after {
        content: '/\00a0';
      }
      &:last-of-type {
        pointer-events: none;
        .item { 
          @include no-underline;
        }
        &:after {
          display: none;
        }
      }
    }
  }
}

// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
  .breadcrumbs {
    font-size: 1.2rem;
  }
}
</style>
