<template>
  <div class="close-menu" @click="$emit('close')">
    <svg
      class="close-menu__icon"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        class="line1"
        :x1="line1.x1"
        :y1="line1.y1"
        :x2="line1.x2"
        :y2="line1.y2"
      />
      <line
        class="line2"
        :x1="line2.x1"
        :y1="line2.y1"
        :x2="line2.x2"
        :y2="line2.y2"
      />
    </svg>
  </div>
</template>
<script>
export default {
  name: 'CloseMenu',
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    direction: {
      type: String,
      default: 'left',
    },
  },
  data() {
    return {
      line1: { x1: 0, y1: 50, x2: 100, y2: 50 },
      line2: { x1: 0, y1: 50, x2: 100, y2: 50 },
      duration: 500,
    }
  },
  computed: {
    slideDirection() {
      if (this.direction === 'left') return '-'
      else return ''
    },
  },
  watch: {
    visible(value) {
      if (value)
        setTimeout(() => {
          this.enter().play()
        }, 800)
      else
        setTimeout(() => {
          this.enter().reverse()
        }, 400)
    },
  },
  methods: {
    enter() {
      const timeline = this.$anime.timeline({
        duration: this.duration,
        easing: 'cubicBezier(0.5, 0, 0, 1)',
      })

      timeline.add({
        targets: this.$el,
        translateX: [`${this.slideDirection}100%`, 0],
      })

      timeline.add(
        {
          targets: this.$el,
          opacity: [0, 1],
        },
        `-=${this.duration}`
      )

      timeline.add({
        targets: this.line1,
        y1: [50, 100],
        y2: [50, 0],
      })

      timeline.add(
        {
          targets: this.line2,
          y1: [50, 0],
          y2: [50, 100],
        },
        `-=${this.duration}`
      )

      return timeline
    },
  },
}
</script>
<style lang="scss" scoped>
.close-menu {
  --size: 2rem;

  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--size);
  height: var(--size);
  opacity: 0;

  &:hover {
    cursor: pointer;
  }

  svg {
    width: 100%;
    height: 100%;
    stroke-width: 7px;
    line {
      stroke: $black;
    }
  }
}
</style>
