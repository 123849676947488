<template>
  <div class="alert">
    <div class="alert__icon">
      <WarningIcon />
    </div>
    <span class="alert__message" v-html="message" />
  </div>
</template>

<script>
import WarningIcon from '~/static/images/status-warning.svg'
export default {
  name: 'Alert',
  components:{
  WarningIcon
  },
  props:{
    message: {
      type: String,
      default: ''
    }
  },
}
</script>

<style lang="scss" scoped>
.alert{
  display: flex;
  width: fit-content;
  flex-wrap: nowrap;
  align-items: center;
  padding-right: spacing('xxsmall');
  background-color: $grey;
  border-radius: 0.4rem;
}

.alert__icon, .alert__message{
  padding-top: spacing('xxsmall');
  padding-bottom: spacing('xxsmall');
}

.alert__icon{
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding-left: spacing('xxsmall');
  padding-right: spacing('xxsmall');
  background-color: $grey;

  > svg {
    width: 2rem;
    fill: $bronze;
  }
}

.alert__message{
  @include p--small;

  color: $bronze;

  &::v-deep{
    p {
      margin: 0;
    }
  }
}
</style>