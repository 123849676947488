<template>
  <div class="footer-menu">
    <div class="mobile-menu show-on-mobile">
      <div v-for="(item, index) in menu" :key="index" class="menu-item">
        <div
          class="title"
          :class="{ open: expandedItem === index }"
          @click="toggleItem(index)"
        >
          <div class="label">
            {{ item.label }}
          </div>
          <ChevronDownIcon />
        </div>

        <!-- Open an item -->
        <transition-expand>
          <div v-if="expandedItem === index" class="content">
            <div class="wrap">
              <span
                v-for="(link, itemIndex) in item.menuItems"
                :key="itemIndex"
                class="link"
              >
                <mp-link
                  :to="$u(link.link)"
                  :target="link.targetBlank ? '_blank' : '_self'"
                  :rel="link.targetBlank ? 'noreferrer nofollow' : false"
                >
                  {{ link.label }}
                </mp-link>
              </span>
            </div>
          </div>
        </transition-expand>
      </div>
    </div>
    <div class="desktop-menu grid dc--4 dg--medium show-on-desktop">
      <div
        v-for="(link, index) in menu"
        :key="'menu-column-' + index"
        class="column"
        :style="index !== 2 ? { order: index } : { order: 1 }"
      >
        <div
          v-for="(child, indexKey) in link.menuItems"
          :key="'child-' + indexKey"
          class="link"
        >
          <img
            v-if="child.icon.filename"
            :src="child.icon.filename"
            :alt="child.label"
            loading="lazy"
          />
          <mp-link
            :to="$u(child.link)"
            :target="child.targetBlank ? '_blank' : '_self'"
            :rel="child.targetBlank ? 'noreferrer nofollow' : false"
          >
            {{ child.label }}
          </mp-link>
        </div>
      </div>
      <div class="salon">
        <h3 class="h--tiny">
          {{ $t('Footer_Salon_Heading') }}
        </h3>
        <mp-link
          :to="saloonLink && saloonLink.cached_url ? $u(saloonLink) : ''"
          class="button medium solid blue"
        >
          <span class="label">
            {{ $t('Footer_Salon_Button_Label') }}
          </span>
        </mp-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ChevronDownIcon from '~/assets/icons/chevron-down-small.svg'

export default {
  name: 'FooterMenu',
  components: {
    ChevronDownIcon,
  },
  props: {
    menu: {
      type: Array,
      required: false,
      default() {},
    },
  },
  data() {
    return {
      expandedItem: null,
    }
  },
  computed: {
    ...mapGetters({ getMenuById: 'storyblok/getMenuById' }),
    saloonLink() {
      return this.getMenuById('footer-menu')?.content?.saloonLink
    },
  },
  methods: {
    toggleItem(item) {
      if (item !== this.expandedItem) {
        this.expandedItem = item
      } else {
        this.expandedItem = null
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.footer-menu {
  .menu-item {
    .title {
      @include caps;
      height: 4.8rem;
      font-weight: 400;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 4rem;
      
      svg {
        height: 0.7rem;
        transition: transform 0.6s cubic-bezier(0.5, 0, 0, 1);
        
        path {
          fill: currentColor;
        }
      }
      
      &.open {
        svg {
          transform: rotate(180deg);
        }
      }
    }

    .content {
      .wrap {
        @include p--medium($font-base);
        padding: 0 4rem 1.6rem 4rem;
        
        .link {
          display: block;
          margin-bottom: 1.2rem;
          
          span {
            text-decoration: line-through;
          }
        }
      }
    }
  }
}

// Tablet ()
@media screen and (min-width: $tablet) {
  .footer-menu {
    .menu-item {
      .title {
        padding: 0 3.2rem;
      }

      .content {
        .wrap {
          padding: 0 3.2rem 1.6rem 3.2rem;
        }
      }
    }
  }
}

// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
  .footer-menu {
    padding-top: 7.2rem;
    padding-bottom: spacing('medium'); // match grid gap

    .desktop-menu {
      .column {
        text-align: left;

        .label {
          @include h--small;
          margin: 0;
          padding-bottom: 3.2rem;
        }

        .link {
          display: flex;
          @include p--medium($font-sans);
          margin-bottom: 1.6rem;

          a {
            border-bottom: 1px solid transparent;

            @media (hover: hover) {
              &:hover {
                border-color: $black;
              }
            }
          }

          img {
            margin-right: 0.8rem;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

        &:last-child {
          margin-right: 0;
        }

        &.social-links {
          order: 4;
          border-top: 0;
          padding: 0;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          margin-left: -1.5rem;

          h3 {
            display: block;
          }
        }

        .social-links__link {
          margin-right: 0;
          margin-bottom: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 4rem;
          height: 4rem;
        }

        .social-links__link__icon {
          fill: $white;
          width: 2rem;
          height: 2rem;
        }
      }

      .salon {
        order: 3;
        text-align: right;

        h3 {
          margin-top: 0;
        }
      }
    }
  }
}
</style>
