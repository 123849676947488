export default {
  actions: {
    closeAll({ dispatch }) {
      dispatch('ui/overlay/hideOverlay', null, { root: true })
      dispatch('ui/added-to-cart/hideAddedToCart', null, { root: true })
      dispatch('ui/country-selector/hideCountrySelector', null, { root: true })
      dispatch('ui/cart-sidebar/hideCartSidebar', null, { root: true })
      dispatch('ui/mobile-sidebar/hideMobileSidebar', null, { root: true })
      dispatch('ui/search/hideSearch', null, { root: true })
      dispatch('ui/restock-notification/hide', null, { root: true })
      dispatch('ui/customer-support/hideSidebar', null, { root: true })
      dispatch('ui/lowest-price-banner/hideLowestPriceBanner', null, {
        root: true,
      })
    },
  },
}
