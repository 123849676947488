export default {
  state () {
    return {
      mobileSidebarOpen: false
    }
  },
  mutations: {
    showMobileSidebar (state) {
      state.mobileSidebarOpen = true
    },
    hideMobileSidebar (state) {
      state.mobileSidebarOpen = false
    }
  },
  actions: {
    showMobileSidebar ({ dispatch, commit }) {
      dispatch('ui/close-all/closeAll', null, { root: true })
      dispatch('ui/overlay/showOverlay', 90, { root: true })
      commit('showMobileSidebar')
    },
    hideMobileSidebar ({ dispatch, commit }) {
      dispatch('ui/overlay/hideOverlay', null, { root: true })
      commit('hideMobileSidebar')
    }
  }
}
