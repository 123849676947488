<template>
  <div class="search">
    <button class="toggle-search" @click="toggleSearch">
      <div class="icon" :title="$t('Toggle_Search_Open')">
        <SearchIcon v-if="!searchOpen" />
        <CrossIcon v-else class="close" />
      </div>
    </button>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SearchIcon from '~/static/images/search.svg'
import CrossIcon from '~/static/images/cross.svg'

export default {
  name: 'ToggleSearch',
  components: {
    SearchIcon,
    CrossIcon,
  },
  computed: {
    ...mapState({
      searchOpen: (state) => state.ui.search.searchOpen,
    }),
  },
  methods: {
    showSearch() {
      this.$store.dispatch('ui/search/showSearch')
    },
    hideSearch() {
      this.$store.dispatch('ui/search/hideSearch')
    },
    toggleSearch() {
      if (this.searchOpen) {
        this.$store.dispatch('ui/search/hideSearch')
      } else {
        this.$store.dispatch('ui/search/showSearch')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.search {
  .toggle-search {
    height: 4rem;
    width: 3.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.2s ease-out;
    @include button-reset;

    @media (hover: hover) {
      cursor: pointer;

      &:hover {
        opacity: 0.6;
      }
    }

    .icon {
      width: 20px;
      height: 20.2px;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 100%;
        height: 100%;
        fill: var(--header-custom-color, var(--header-color));

        &.close {
          width: 1.4rem;
          height: 1.4rem;
        }
      }
    }
  }
}

// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
  .search {
    .toggle-search {
      .icon {
        width: 23px;
        height: 23.2px;
      }
    }
  }
}
</style>
