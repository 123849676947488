<template>
  <div class="pill-filter">
    <span
      v-for="(item) in filter"
      :key="item.value"
      :class="{'selected': item.selected}"
      @click="$emit('clickedFilter', item.value)"
    >
      {{ item.label }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'PillFilter',
  props: {
    filter: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.pill-filter {
  padding: 1.2rem;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  @include p--small($font-alternate);
  @include hide-scrollbars;
  span {
    border: 1px solid $black;
    border-radius: 24px;
    padding: 0 2.4rem;
    height: 4rem;
    margin: 0 .4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    line-height: 1.2em;
    text-transform: capitalize;
    &.selected {
      background: $black;
      color: $white;
    }
  }
  &:after {
    content: '';
    display: block;
    padding-right: 1.2rem;
  }
}

// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
  .pill-filter {
    flex-wrap: wrap;
    span {
      //height: 3.6rem;
      //@include p--medium;
      margin: .4rem;
      cursor: pointer;
      @media (hover: hover) {
        &:hover {
          background: $black;
          color: $white;
        }
      }
    }
  }
}
</style>
