<template>
  <div>
    <div class="error-page">
      <div class="error-page__hero">
        <div class="error-page__hero__bg-wrapper mobile-format--square">
          <ResponsiveImage
            :lazyload="true"
            class="error-page__hero__bg"
            :mobile-src="bgImage?.filename"
            :mobile-display-size="'100vw'"
            :desktop-display-size="'100vw'"
            :lazy-load="false"
          />
        </div>
        <template v-if="error.statusCode === 404">
          <div class="error-page__hero__content">
            <Headline
              class="error-page__hero__headline"
              headline-type="h1"
              headline-size="display--huge"
              :headline="{ html: '404' }"
              :lead="{ html: $t('Page_Not_Found') }"
            />
            <mp-link :to="$u('')">
              <Button
                class="error-page__hero__button"
                color="blue-dark"
                button-style="solid"
                :link-as-string="''"
                :label="$t('Global_Continue_Shopping')"
              />
            </mp-link>
          </div>
        </template>
        <template v-else>
          <div class="error-page__hero__content">
            <Headline
              class="error-page__hero__headline"
              headline-type="h1"
              headline-size="display--huge"
              :headline="{ html: error.statusCode }"
              :lead="{ html: error.message }"
            />
            <mp-link :to="$u('')">
              <Button
                class="error-page__hero__button"
                color="blue-dark"
                button-style="solid"
                :link-as-string="''"
                :label="$t('Global_Continue_Shopping')"
              />
            </mp-link>
          </div>
        </template>
      </div>
      <ProductListSwipeable
        v-for="(productList, i) in productListSwipeables"
        :key="`error-page__products${i}${_uid}`"
        class="error-page__products"
        v-bind="productList"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Error',
  props: {
    error: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    bgImage() {
      return this.$store.getters['storyblok/settings']?.pageNotFoundImage
    },
    productListSwipeables() {
      const res =
        this.$store.getters['storyblok/settings']
          ?.pageNotFoundProductListSwipeable
      return res
    },
  },
  mounted() {
    this.gtm_trackPage('404')
  },
}
</script>

<style lang="scss">
.error-page {
  position: relative;

  .error-page__hero {
    position: relative;
  }

  .error-page__hero__bg-wrapper {
    z-index: 0;
  }

  .error-page__hero__bg {
    &::before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(white, 0.5);
      z-index: 1;
    }
  }
  .error-page__hero__content {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
  }

  .error-page__hero__headline {
    margin: 0.8rem 0;
  }

  .error-page__hero__button {
    @include p--large;
    margin-top: 2.4rem;

    .label {
      font-size: 1.4rem;
    }
  }

  .error-page__products {
    .list-header {
      padding-bottom: spacing('xsmall');
    }
  }
}
@media screen and (min-width: $tablet-landscape) {
  .error-page {
    .error-page__hero__bg-wrapper {
      height: calc(80 * var(--vh, 1vh) - var(--header-complete-height));
    }
    .link .label {
      font-size: 1.8rem;
    }
  }
}
</style>
