import crypto from 'crypto'
import Vue from 'vue'

import { mapGetters } from 'vuex'

Vue.mixin({
  computed: {
    ...mapGetters({
      market: 'frontend/market',
      pricelist: 'frontend/pricelist',
      currentLanguageCode: 'frontend/currentLanguageCode',
      currentCountryCode: 'frontend/currentCountryCode',
      gtmCart: 'centra-cart/cart',
    }),
  },
  mounted() {
    // Lazy init of the dataLayer that the GTM initialize will use
    window.dataLayer = window.dataLayer || []

    // Don't hate me for using window here
    window.queuedProductImpressions = window.queuedProductImpressions || []
  },
  methods: {
    gtm_trackPage(pageType, pageName) {
      pageName = pageName || pageType

      const payload = {
        event: 'pageView',
        pageType,
        pageName,
      }

      if (this.$store.state?.account?.account?.isLoggedIn) {
        // How to make this work on initial page load since this fires before
        // the account xhr has finished loading?
        payload.userId = this.$store.state.account.account.customer
      }

      payload.language = this.currentLanguageCode
      payload.country = this.currentCountryCode

      window.dataLayer.push(payload)
      window.dataLayer.push({
        event: 'dynx_rem',
        google_tag_params: {
          ecomm_pagetype: pageType,
        },
      })
    },

    gtm_trackProductClick(product, list) {
      const productId = product.productSku
      const name = product.name
      if (!name) {
        console.error(
          `gtm_trackProductClick: Product id=${productId} name not found`
        )
        return
      }
      const currencyCode = this.pricelist?.currency?.uri
      if (!currencyCode) {
        console.error(
          `gtm_trackProductClick: Product id=${productId} currency not found`
        )
        return
      }
      const payload = {
        event: 'productClick',
        ecommerce: {
          currencyCode,
          click: {
            actionField: {
              list,
            },
            products: [
              {
                id: productId,
                name,
                price: product.priceAsNumber,
                category: product.product_type,
                url: this.$config.baseUrl + this.$u(product.url),
                sku: product.sku,
                imageUrl: product.media[0],
                brand: product.brandName,
              },
            ],
          },
        },
      }
      console.debug('gtm_trackProductClick', payload)
      window.dataLayer.push(payload)
      this.$store.dispatch('gtm/lastPushedClick', payload)
    },

    async gtm_trackProductDetail(product) {
      const payload = {
        event: 'productDetail',
        ecommerce: {
          currencyCode: this.pricelist.currency.currency,
          detail: {
            products: Object.values(product.items).map((_item) => ({
              name: product.name,
              id: product.productSku,
              brand: product.brandName,
              category: product.product_type,
              price: parseFloat(product.priceAsNumber),
              url: `${this.$config.baseUrl}${this.$u(product.url)}`,
              imageUrl: this.$imgproxy.transform(
                product.mainImage,
                'extend:1/resize:fit/w:1200/h:630/q:70'
              ),
            })),
          },
        },
      }

      if (this.$store.state.gtm) {
        const pushedClick = await this.$store.dispatch('gtm/popLastPushedClick')
        if (pushedClick) {
          const list = pushedClick?.ecommerce?.click?.actionField?.list
          if (list) {
            payload.ecommerce.detail.actionField = { list }
          }
        }
      }

      window.dataLayer.push(payload)
      window.dataLayer.push({
        event: 'dynx_rem',
        google_tag_params: {
          ecomm_pagetype: 'product',
          ecomm_prodid: [product.productSku],
        },
      })
    },

    gtm_trackProductImpression(impressions) {
      window.dataLayer.push({
        event: 'productImpressions',
        ecommerce: {
          currencyCode: this.pricelist.currency.currency,
          impressions,
        },
      })
    },

    /**
     * We queue impressions to 20 and flush them either when number 20 is
     * pushed or after the queue has existed for 0.5 seconds. The reason for
     * this is that you can push too much data by mistake into GTM and we
     * don't want to do that, while we also want to push the separate product
     * as they appear in the browser window
     */
    gtm_queueProductImpression(product, list, position) {
      try {
        window.queuedProductImpressions.push({
          id: product.productSku,
          name: product.name,
          price: product.priceAsNumber,
          category: product.product_type,
          list,
          position,
        })
        const flushImpressionQueue = () => {
          this.gtm_trackProductImpression(window.queuedProductImpressions)
          window.queuedProductImpressions = []
        }
        clearTimeout(window.impressionPushTimer)
        if (window.queuedProductImpressions.length >= 20) {
          flushImpressionQueue()
        } else {
          window.impressionPushTimer = setTimeout(flushImpressionQueue, 500)
        }
      } catch (e) {
        // Failing quietly
        // console.error('gtm_queueProductImpression failed')
      }
    },

    gtm_trackAddToCart(item, quantity) {
      if (!this.gtmCart?.totals) {
        console.warn('Cart totals are empty, cannot track')
      }
      const {
        grandTotalPriceAsNumber,
        itemsTotalPriceAsNumber,
        totalDiscountPriceAsNumber,
      } = this.gtmCart.totals
      const totalWithShipping = grandTotalPriceAsNumber ?? 0
      const totalWithoutShipping =
        (itemsTotalPriceAsNumber ?? 0) + (totalDiscountPriceAsNumber ?? 0)

      const payload = {
        event: 'addToCart',
        eventLabel: item.productName,
        ecommerce: {
          currencyCode: this.pricelist.currency.currency,
          add: {
            products: [
              {
                name: item.productName,
                id: item._product.productSku,
                price: parseFloat(item.priceEachAsNumber),
                category: item._product.product_type,
                brand: item.brandName,
                quantity: quantity || item.quantity,
                image: item._product.mainImage,
                title: item._product.name,
                url: this.$config.baseUrl + this.$u(item._product.url),
                sku: item.ean,
              },
            ],
          },
          cartItems: {
            products: this.gtmCart.items.map((item) => ({
              name: item.productName,
              id: item._product.productSku,
              price: parseFloat(item.priceEachAsNumber),
              category: item._product.product_type,
              brand: item.brandName,
              quantity: item.quantity,
              image: item._product.mainImage,
              title: item._product.name,
              url: this.$config.baseUrl + this.$u(item._product.url),
              sku: item.ean,
            })),
            productIds: this.gtmCart.items.map(
              (item) => item._product.productSku
            ),
            numberOfItems: this.gtmCart.items
              .map((item) => item.quantity)
              .reduce((a, b) => a + b),
          },
          order: {
            totalWithShipping,
            totalWithoutShipping,
            checkoutUrl: this.$config.baseUrl + this.$u('checkout'),
          },
        },
      }
      window.dataLayer.push(payload)
    },

    gtm_trackRemoveFromCart(item, quantity) {
      window.dataLayer.push({
        event: 'removeFromCart',
        eventLabel: item.productName,
        ecommerce: {
          currencyCode: this.pricelist.currency.currency,
          remove: {
            products: [
              {
                name: item.productName,
                id: item._product.productSku,
                price: parseFloat(item.priceEachAsNumber),
                category: item._product.product_type,
                brand: item.brandName,
                quantity,
              },
            ],
          },
        },
      })
    },

    gtm_trackCheckoutStart(cart) {
      if (cart?.items) {
        window.dataLayer.push({
          event: 'dynx_rem',
          google_tag_params: {
            ecomm_pagetype: 'cart',
            ecomm_prodid: cart.items.map((item) => item._product.productSku),
            ecomm_totalvalue: parseFloat(cart.totals.grandTotalPrice),
          },
        })
        return this.gtm_trackCheckoutStep(cart, 'start', 'checkout')
      }
    },

    gtm_trackCheckoutStep(cart, step, event) {
      if (!cart) {
        return
      }
      event = event || 'checkoutOption'
      window.dataLayer.push({
        event,
        ecommerce: {
          cartGrandTotal: cart.totals.grandTotalPrice,
          cartGrandTotalAsNumber: parseFloat(
            cart.totals.grandTotalPriceAsNumber
          ),
          currencyCode: this.pricelist.currency.currency,
          checkout: {
            actionField: { step },
            products: cart.items.map((item) => ({
              name: item._product.name,
              id: item._product.productSku,
              price: parseFloat(item.priceEachAsNumber),
              category: item._product.product_type,
              brand: item.brandName,
              quantity: item.quantity,
            })),
            productIds: cart.items.map((item) => item._product.productSku),
            numberOfProducts: cart.items
              .map((item) => item.quantity)
              .reduce((a, b) => a + b),
          },
        },
      })
    },

    gtm_trackSearch(searchQuery) {
      window.dataLayer.push({
        event: 'search',
        searchQuery,
      })
    },

    gtm_trackPurchase(order) {
      try {
        if ('localStorage' in window) {
          if (window.localStorage.getItem(order.order)) {
            console.warn(`Order ${order.order} purchase already tracked`)
            return
          }
        } else if (this.$cookies.get(order.order)) {
          console.warn(`Order ${order.order} purchase already tracked`)
          return
        }

        if ('localStorage' in window) {
          window.localStorage.setItem(order.order, '')
        } else {
          this.$cookies.set(order.order, 1, {
            maxAge: 3600,
          })
        }

        let coupon = ''
        if (order?.discounts?.vouchers) {
          coupon = Object.values(order.discounts.vouchers)
            .map((voucher) => voucher.voucher)
            .join(', ')
        }

        const md5HashedEmail = crypto
          .createHash('md5')
          .update(order.address.email?.toLowerCase().trim())
          .digest('hex')
        const hashedEmail = crypto
          .createHash('sha256')
          .update(order.address.email)
          .digest('hex')
        const hashedFirstname = crypto
          .createHash('sha256')
          .update(order.address.firstName)
          .digest('hex')
        const hashedLastname = crypto
          .createHash('sha256')
          .update(order.address.lastName)
          .digest('hex')
        const hashedPhoneNumber = crypto
          .createHash('sha256')
          .update(order.address?.phoneNumber)
          .digest('hex')

        const payload = {
          event: 'purchase',
          ecommerce: {
            currencyCode: order.currency,
            purchase: {
              actionField: {
                email: order.address.email,
                md5HashedEmail,
                hashedEmail,
                hashedFirstname,
                hashedLastname,
                hashedPhoneNumber,
                phoneNumber: order.address?.phoneNumber,
                id: order.order, // Transaction ID. Required for purchases and refunds.
                affiliation: this.market.name,
                revenue: parseFloat(order.totals.grandTotalPriceAsNumber), // Total transaction value (incl. tax and shipping)
                tax: parseFloat(order.totals.grandTotalPriceTaxAsNumber),
                shipping: parseFloat(order.totals.shippingPriceAsNumber),
                revenueSubtotal: parseFloat(
                  order.totals.grandTotalPriceAsNumber -
                    order.totals.grandTotalPriceTaxAsNumber -
                    order.totals.shippingPriceAsNumber
                ),
                coupon,
              },
              products: order.items.map((item) => ({
                id: item._product.productSku,
                name: item._product.name,
                sku: item.ean,
                price: parseFloat(item.priceEachAsNumber),
                brand: item.brandName,
                quantity: item.quantity,
                category: item._product.product_type,
              })),
              productIds: order.items.map((item) => item._product.productSku),
              numberOfProducts: order.items
                .map((item) => item.quantity)
                .reduce((a, b) => a + b),
            },
          },
        }
        window.dataLayer.push(payload)
        window.dataLayer.push({
          event: 'dynx_rem',
          google_tag_params: {
            ecomm_pagetype: 'purchase',
            ecomm_prodid: order.items.map((item) => item._product.productSku),
            ecomm_totalvalue: parseFloat(order.totals.grandTotalPriceAsNumber),
          },
        })
      } catch (err) {
        console.log('GTM purchase tracking failed', err)
      }
    },
  },
})
