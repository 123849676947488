<template>
  <div
    class="modal-header"
    :class="[
      'icon--' + iconPosition,
      'title--' + titlePosition
    ]"
  >
    <span
      class="icon"
      @click="close"
    >
      <slot v-if="customIconPassed" name="icon" />
      <CrossIcon v-else />
    </span>
    <div class="title">
      <span class="main">
        {{ title }}
      </span>
      <span class="sub">
        {{ subtitle }}
      </span>
    </div>
  </div>
</template>

<script>
import CrossIcon from '~/assets/icons/cross.svg'

export default {
  name: 'ModalHeader',
  components: {
    CrossIcon
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },

    /**
     * Title Position
     * 'left' or 'center'
     */
    titlePosition: {
      type: String,
      default: 'center'
    },

    /**
     * Icon Position
     * 'left' or 'right
     */
    iconPosition: {
      type: String,
      default: 'left'
    }
  },
  computed: {
    customIconPassed () {
      return !!this.$slots.icon
    }
  },
  methods: {
    close () {
      /**
       * Emit close event to parent
       */
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
  .modal-header {
    --side-padding: 1.6rem;
    height: 7.2rem;
    margin: 0;
    color: $black;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    background: $white;
    border-bottom: 1px solid rgba($black,0.1);
    &.icon--left {
      grid-template-areas: "icon title none";
      .icon {
        justify-self: flex-start;
      }
    }
    &.icon--right {
      grid-template-areas: "none title icon";
      .icon {
        justify-self: flex-end;
      }
    }
    &.title--left {
      grid-template-areas: "title title icon";
      .title {
        justify-self: flex-start;
        padding-left: var(--side-padding);
      }
    }

    .title {
      grid-area: title;
      justify-self: center;
      .main {
        @include h--medium;
        font-size: 2.4rem !important;
        font-family: $font-base;
        color: $blue;
      }
      .sub {
        @include p--medium;
      }
    }
    .icon {
      grid-area: icon;
      padding: 0 var(--side-padding);
      cursor: pointer;
      svg {
        fill: $black;
        width: 1.2rem;
        height: 1.2rem;
      }
    }
  }

  // Tablet (Portrait)
  @media screen and (min-width: $tablet) {
    .modal-header {
      --side-padding: 3.2rem;
    }
  }

  // Tablet (Landscape)
  @media screen and (min-width: $tablet-landscape) {
    .modal-header {
      --side-padding: 4rem;
    }
  }

  // Laptop
  @media screen and (min-width: $laptop) {
  }

  // Desktop
  @media screen and (min-width: $desktop) {
  }
</style>
