<template>
  <div class="lowest-price-banner">
    <span class="lowest-price-text"><Information /> {{ message }}</span>
    <button
      class="close-button"
      @click="$store.dispatch('ui/lowest-price-banner/hideLowestPriceBanner')"
    >
      <CrossIcon />
    </button>
  </div>
</template>

<script>
import Information from '~/assets/icons/information.svg'
import CrossIcon from '~/static/images/cross.svg'

export default {
  components: {
    Information,
    CrossIcon,
  },
  props: {
    message: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.lowest-price-banner {
  top: var(--header-complete-height);
  position: fixed;
  width: 100%;
  z-index: 10 !important;
  background-color: $grey;
  padding: 24px;
  display: flex;
  gap: 8px;

  .lowest-price-text {
    @include p--medium;
    display: flex;
    align-items: flex-start;
    gap: 8px;

    svg {
      margin-top: 2px;
      height: 14px;
      width: 14px;
      min-height: 14px;
      min-width: 14px;
    }
  }

  .close-button {
    cursor: pointer;
    padding: 1px;
    background: none;
    appearance: none;
    border: none;
    display: flex;
    align-items: flex-start;

    svg {
      margin-top: 2px;
      height: 14px;
      width: 14px;
    }
  }

  @media screen and (min-width: $tablet-landscape) {
    display: none;
  }
}
</style>
