export default {
  state() {
    return {
      open: false,
    }
  },
  mutations: {
    showLowestPriceBanner(state) {
      state.open = true
    },
    hideLowestPriceBanner(state) {
      state.open = false
    },
  },
  actions: {
    showLowestPriceBanner({ dispatch, commit }) {
      dispatch('ui/close-all/closeAll', null, { root: true })
      commit('showLowestPriceBanner')
    },
    hideLowestPriceBanner({ commit }) {
      commit('hideLowestPriceBanner')
    },
  },
}
