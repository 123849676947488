export const lastSelectionIdKey = 'last_selection_id'

export default {
  namespace: true,
  state () {
    return {
      lastSelectionId: ''
    }
  },
  mutations: {
    setLastSelectionId (state, id) {
      state.lastSelectionId = id
    }
  },
  actions: {
    initialize ({ commit }) {
      const lastSelectionId = localStorage.getItem(lastSelectionIdKey)
      commit('setLastSelectionId', lastSelectionId || '')
    },
    set ({ commit }, lastSelectionId) {
      commit('setLastSelectionId', lastSelectionId)
      localStorage.setItem(lastSelectionIdKey, lastSelectionId)
    }
  },
  getters: {
    lastSelectionId: state => state.lastSelectionId
  }
}
