export default {
  state () {
    return {
      sidebarOpen: false
    }
  },
  mutations: {
    showSidebar (state) {
      state.sidebarOpen = true
    },
    hideSidebar (state) {
      state.sidebarOpen = false
    }
  },
  actions: {
    showSidebar ({ dispatch, commit }) {
      dispatch('ui/close-all/closeAll', null, { root: true })
      dispatch('ui/overlay/showOverlay', 90, { root: true })
      commit('showSidebar')
    },
    hideSidebar ({ dispatch, commit }) {
      dispatch('ui/overlay/hideOverlay', null, { root: true })
      commit('hideSidebar')
    }
  }
}
