import type { GetterTree, MutationTree, ActionTree } from 'vuex'
import dayjsDefault, { Dayjs } from 'dayjs'
import type { CentraOrderReceipt } from '@made-people/centra-models'
import { CrossSellInfo, RootState } from './types'
import { setupDayjs } from '~/plugins/dayjs'
import isAxiosError from '~/types/is-axios-error'

const dayjs = setupDayjs(dayjsDefault)

export const CROSS_SELL_STORAGE_ID = 'cross_sell'

export function state(): RootState['cross-sell'] {
  return {
    active: undefined,
  }
}

export const getters: GetterTree<RootState['cross-sell'], RootState> = {
  isActive(state) {
    return typeof state.active === 'object' && state.active !== null
  },
}

export const mutations: MutationTree<RootState['cross-sell']> = {
  set(state, payload: CrossSellInfo) {
    if (
      typeof payload === 'object' &&
      payload !== null &&
      'orderId' in payload &&
      (typeof payload.orderId === 'number' ||
        typeof payload.orderId === 'string') &&
      'expiresString' in payload &&
      typeof payload.expiresString === 'string'
    ) {
      if (typeof payload.orderId === 'string') {
        payload.orderId = parseInt(payload.orderId)
      }
      if (isNaN(payload.orderId)) {
        return
      }

      state.active = payload

      let toSaveInStorage = payload
      if ('expires' in payload) {
        toSaveInStorage = {
          orderId: payload.orderId,
          expiresString: payload.expiresString,
        }
      }
      localStorage.setItem(
        CROSS_SELL_STORAGE_ID,
        JSON.stringify(toSaveInStorage)
      )
    }
  },
  unset(state) {
    state.active = undefined
    localStorage.removeItem(CROSS_SELL_STORAGE_ID)
  },
}

export const actions: ActionTree<RootState['cross-sell'], RootState> = {
  initialize({ commit }) {
    const crossSellRaw = localStorage.getItem(CROSS_SELL_STORAGE_ID)
    try {
      const crossSell = JSON.parse(crossSellRaw ?? '')
      commit('set', crossSell)
    } catch (err) {}
  },
  unset({ commit }) {
    commit('unset')
  },
  setFromOrderReceipt({ commit }, orderReceipt: CentraOrderReceipt) {
    if (
      typeof orderReceipt.order === 'string' &&
      typeof orderReceipt.date === 'string' &&
      typeof orderReceipt.crossSell === 'object' &&
      orderReceipt.crossSell !== null &&
      orderReceipt.crossSell.isAllowed === true &&
      typeof orderReceipt.crossSell.timeLimit === 'number' &&
      orderReceipt.crossSell.timeLimit > 0
    ) {
      try {
        const expirationDate = dayjs
          .tz(orderReceipt.date, 'Europe/Amsterdam')
          .add(orderReceipt.crossSell.timeLimit, 'minutes')
        const orderId = parseInt(orderReceipt.order)
        if (isNaN(orderId)) {
          throw new TypeError(
            '[cross-sell/setFromOrderReceipt]: Could not parse order number'
          )
        }

        if (expirationDate.isBefore(dayjs().utc())) {
          return
        }

        commit('set', {
          orderId,
          expires: expirationDate.utc(),
          expiresString: expirationDate.utc().toJSON(),
        })
      } catch (e) {}
    }
  },
  getExpiration({ state, commit }) {
    if (typeof state.active !== 'undefined') {
      let expirationDate: Dayjs | undefined
      if (
        typeof state.active.expires !== 'undefined' &&
        state.active.expires.isValid()
      ) {
        expirationDate = state.active.expires
      } else {
        try {
          // I hope that Centra always sends the date in Amsterdam's time zone....
          expirationDate = dayjs.utc(state.active.expiresString)
          commit('set', { ...state.active, expires: expirationDate })
        } catch (err) {}
      }
      return expirationDate
    }
  },
  async checkIfProductsAreCrossSellable(
    { state, getters },
    productIds: string[]
  ) {
    if (typeof productIds === 'string') {
      productIds = [productIds]
    }

    if (
      !getters.isActive ||
      !Array.isArray(productIds) ||
      productIds.some((id) => isNaN(parseInt(id))) ||
      productIds.length < 1
    ) {
      return false
    }

    try {
      const response = await this.$backendApi.get<{
        successful: string[]
        failed?: string[]
      }>(`/order/${state.active!.orderId}/cross-sell/${productIds.join(',')}`)
      const result: Record<string, boolean> = {}
      for (const productId of response.data.successful) {
        result[productId] = true
      }
      for (const productId of response.data.failed ?? []) {
        result[productId] = false
      }
      return result
    } catch (err) {
      console.error(
        `[cross-sell/checkIfProductsAreCrossSellable]: Could not check if products with ids ${productIds.join(
          ','
        )} are cross-sell addable to order ${state.active!.orderId}`,
        err
      )
    }
  },
  async addToOrder({ state, dispatch, commit }, productId: string) {
    try {
      const selection = (
        await this.$backendApi.post(
          `/order/${state.active!.orderId}/cross-sell/add/${productId}`
        )
      ).data
      dispatch('centra-cart/paymentResultByReceipt', selection.selection, {
        root: true,
      })
    } catch (err) {
      // eslint-disable-next-line camelcase
      if (isAxiosError<{ error_code: string; message: string }>(err)) {
        if (err.response?.status === 403) {
          commit('unset')
        }
        return err.response?.data
      } else {
        console.error(
          `[cross-sell/addToOrder]: Could not add product with id ${productId} to order ${
            state.active!.orderId
          }`,
          err
        )
        return undefined
      }
    }
  },
}
