import Vue from 'vue'

export default {
  state() {
    return {
      token: null,
      allReviews: {},
      products: {},
      loading: false,
      settings: null,
    }
  },
  mutations: {
    setToken(state, token) {
      state.token = token
    },
    setSettings(state, settings) {
      state.settings = settings
    },
    setAllReviews(state, payload) {
      state.allReviews = payload
    },
    setProduct(state, payload) {
      Vue.set(state.products, payload.sku, payload.product)
    },
    setLoading(state, payload) {
      state.loading = payload
    },
    clearProducts(state) {
      state.products = {}
    },
    clearToken(state) {
      state.apiKey = null
      state.token = null
    },
    clearAllReviews(state) {
      state.allReviews = {}
    },
  },
  actions: {
    clearProducts({ commit }) {
      commit('clearProducts')
      commit('clearToken')
    },
    clearAllReviews({ commit }) {
      commit('clearAllReviews')
      commit('clearToken')
    },
    /**
     * Get token needed for yotpo api
     * @param commit
     * @param rootState
     * @return {Promise<unknown>}
     */
    async getToken({ commit }) {
      try {
        const response = await this.$backendApi.get('yotpo/token')
        commit('setToken', response.data.token)
        return response.data.token
      } catch (e) {
        console.error('Error when setting Yotpo token', e)
      }
    },

    async getSettings({ commit }) {
      try {
        const response = await this.$backendApi.get('yotpo/settings')
        commit('setSettings', response.data)
        return response.data
      } catch (e) {
        console.error('Error when fetching yotpo settings', e)
      }
    },
    /**
     * Get all reviews
     * @param commit
     * @param state
     * @param dispatch
     * @param app
     * @return {Promise<void>}
     */
    async loadAllReviews({ commit, state, dispatch }) {
      if (!state.token) {
        await dispatch('getToken')
      }
      // We are using the search api, because the normal version comes in asc order
      const feedUrl = `https://api.yotpo.com/v1/reviews/${state.apiKey}/filter.json?utoken=${state.token}`
      const data = {
        per_page: 10,
        scores: [4, 5],
      }
      fetch(feedUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }).then((response) => {
        response.json().then((json) => {
          commit('setAllReviews', json.response.reviews)
        })
      })
    },
    async loadGlobalAverage({ state, dispatch }) {
      if (!state.token) {
        await dispatch('getToken')
      }
      const feedUrl = `https://api.yotpo.com/products/${state.apiKey}/yotpo_site_reviews/bottomline`
      fetch(feedUrl).then((response) => {
        response.json().then((json) => {
          console.log(json.response)
        })
      })
    },
    async loadProductReview({ commit, state, dispatch }, sku) {
      if (!state.products[sku]) {
        if (!state.settings?.reviewsAppKey) {
          await dispatch('getSettings')
        }
        const feedUrl = `https://api.yotpo.com/v1/widget/${state.settings.reviewsAppKey}/products/${sku}/reviews.json`
        return fetch(feedUrl)
          .then((response) => {
            return response.json().then((json) => {
              commit('setProduct', { sku, product: json.response })
            })
          })
          .catch((e) => {
            console.error(`error when fetching yotpo review ${sku}`, e)
          })
      }
    },
    async loadProductReviewsAll({ commit, state, dispatch }, sku) {
      if (
        !state.products[sku] ||
        state.products[sku].bottomline.total_review >
          state.products[sku].reviews.length
      ) {
        if (!state.settings?.reviewsAppKey) {
          await dispatch('getSettings')
        }
        commit('setLoading', true)
        const feedUrl = `https://api.yotpo.com/v1/widget/${state.settings.reviewsAppKey}/products/${sku}/reviews.json?per_page=1000`
        return fetch(feedUrl).then((response) => {
          return response.json().then((json) => {
            commit('setProduct', { sku, product: json.response })
            commit('setLoading', false)
          })
        })
      }
    },
  },
  getters: {
    getProductReview: (state) => (sku) => {
      if (state.products[sku]) {
        return state.products[sku]
      }
      return null
    },
  },
}
