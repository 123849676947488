import FrontendStore from '@made-people/centra-storyblok-nuxt-shared/lib/store/frontend'

export default {
  ...FrontendStore,
  getters: {
    ...FrontendStore.getters,
    currentCurrencyCode: (_state, getters) =>
      getters.pricelist?.currency?.currency,
  },
}
