export default {
  state() {
    return {
      searchOpen: false,
    }
  },
  mutations: {
    showSearch(state) {
      state.searchOpen = true
    },
    hideSearch(state) {
      state.searchOpen = false
    },
  },
  actions: {
    showSearch({ dispatch, commit }) {
      dispatch('ui/close-all/closeAll', null, { root: true })
      dispatch('ui/overlay/showOverlay', 10, { root: true })
      commit('showSearch')
    },
    hideSearch({ dispatch, commit }) {
      dispatch('ui/overlay/hideOverlay', null, { root: true })
      commit('hideSearch')
    },
  },
}
