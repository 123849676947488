export default {
  methods: {
    copyStyleProperties(selector) {
      let customProperties = {};
      const elementStylesObj = this.$el?.parentElement.querySelector(selector)?.style;
      if (!elementStylesObj) return customProperties;
      const elementStylesArray = Object.keys(elementStylesObj);
      if (elementStylesArray.length) {
        for (let i = 0; i < elementStylesArray.length; i++) {
          if (!elementStylesObj[i]) break;
          const key = elementStylesObj[i];
          const value = elementStylesObj.getPropertyValue(key);
          customProperties = {
            ...customProperties,
            [key]: value,
          }
        }
      }
      return customProperties;
    }
  }
}
