<template>
  <mp-link ref="carticon" :to="$u('checkout')" class="toggle-cart">
    <client-only>
      <div class="icon" title="Open cart">
        <CartIcon />
      </div>
      <div v-if="cartQty > 0" class="quantity">
        <span>{{ cartQty }}</span>
      </div>
    </client-only>
  </mp-link>
</template>

<script>
import CartIcon from '~/static/images/cart.svg'

export default {
  name: 'ToggleCart',
  components: { CartIcon },
  props: {
    device: {
      type: String,
      default: 'mobile',
    },
    cartQty: {
      type: Number,
      required: true,
    },
  },
  watch: {
    cartQty() {
      const cartIcon = this.$refs.carticon?.$el
      if (cartIcon) {
        cartIcon.classList.add('pop')
        setTimeout(() => {
          cartIcon.classList.remove('pop')
        }, 2000)
      }
    },
  },
  // methods: {
  //   toggleMiniCart () {
  //     this.$store.dispatch('ui/toggleMiniCart')
  //   }
  // }
}
</script>

<style lang="scss" scoped>
.toggle-cart {
  height: 4rem;
  width: 3.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (hover: hover) {
    cursor: pointer;

    &:hover svg {
      opacity: 0.6;
    }
  }

  .icon {
    width: 17.5px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -1px;

    svg {
      transition: opacity 0.2s ease-out;
      width: 100%;
      height: 100%;
      fill: var(--header-custom-color, var(--header-color));
    }
  }

  .quantity {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.6rem;
    height: 1.6rem;
    background: $blue;
    @include p--small;
    font-size: 1.1rem;
    line-height: 100%;
    letter-spacing: -0.1em;
    color: $white;
    border-radius: 100%;
    position: absolute;
    -webkit-font-smoothing: subpixel-antialiased;
    right: 0.2rem;
    top: 0.4rem;

    span {
      margin-top: 0px;
      margin-left: -1px;
    }
  }

  &.pop {
    .quantity {
      animation: blink 0.6s both;
    }
  }
}

@media screen and (min-width: $tablet-landscape) {
  .toggle-cart {
    .icon {
      width: 21px;
      height: 24px;
    }
  }
}
</style>
