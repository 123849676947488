<template>
  <header ref="header" :class="{
    'is-sticky solid-background': isSticky,
    'solid-background': hover,
    'solid-background': searchOpen,
    'with-topbar': topbarTexts,
  }">
    <Topbar v-if="topbarTexts" :appearance="topbarAppearance" :topbar-texts="topbarTexts"
      :foreground-color="topbarForegroundColorName" :foreground-color-hex="topbarForegroundColorHex"
      :background-color="topbarBackgroundColorName" :background-color-hex="topbarBackgroundColorHex" />
    <div
      class="main-header"
      :style="[backgroundColorStyle, textColorStyle]"
      @mouseover="hover = true"
      @mouseleave="hover = false">
      <div class="left">
        <ToggleMenu />
        <ToggleSearch />
      </div>
      <mp-link :to="$u('')" title="Xlash" class="logo">
        <LogoIcon />
      </mp-link>
      <div class="right">
        <HeaderText
          v-for="(headerText, i) in headerTexts"
          :key="`header-text${_uid}${i}`"
          :class="{ margin: i === headerTexts.length - 1 }"
          v-bind="headerText"
        />
        <ToggleAccount v-if="yotpoSettings.loyaltyEnabled" />
        <ToggleHelp v-if="showCustomerSupportSidebar" />
        <ToggleCart :cart-qty="cartQty" device="mobile" />
      </div>
    </div>
    <div v-if="searchOpen" class="search-results-wrapper">
      <SearchResults />
    </div>
  </header>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { color } from '@made-people/centra-storyblok-nuxt-shared/lib/util/ColorHexCode'
import LogoIcon from '~/static/images/logo.svg'
import ToggleMenu from '~/components/header/ToggleMenu'
import ToggleSearch from '~/components/header/ToggleSearch'
import ToggleCart from '~/components/header/ToggleCart'
import ToggleHelp from '~/components/header/ToggleHelp'
import SearchResults from '~/components/search/SearchResults.vue'
import ToggleAccount from '@/components/header/ToggleAccount'
import DomUtils from '@/mixins/domUtils'

export default {
  name: 'Header',
  components: {
    ToggleAccount,
    ToggleMenu,
    ToggleSearch,
    ToggleCart,
    ToggleHelp,
    LogoIcon,
    SearchResults,
  },
  mixins: [ DomUtils ],
  props: {
    cartQty: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isSticky: false,
      offset: 100,
      scrollpos: 0,
      hover: false,
    }
  },
  computed: {
    ...mapState({
      searchOpen: (state) => state.ui.search.searchOpen,
      yotpoSettings: (state) => state.yotpo.settings,
    }),
    ...mapGetters({
      countryCode: 'frontend/currentCountryCode',
      settings: 'storyblok/settings',
    }),
    headerSettings() {
      return this.$store.state.storyblok.stories.find(
        (x) => x.data?.content?.component === 'CountryInformation'
      )?.data?.content
    },
    topbarTexts() {
      return this.headerSettings?.topbarTexts
    },
    topbarForegroundColorName() {
      return this.headerSettings?.topbarFgColor
    },
    topbarForegroundColorHex() {
      return this.headerSettings?.topbarFgColorHex
    },
    topbarBackgroundColorName() {
      return this.headerSettings?.topbarBgColor
    },
    topbarBackgroundColorHex() {
      return this.headerSettings?.topbarBgHex
    },
    topbarAppearance() {
      return this.headerSettings?.topbarAppearance
    },
    headerTexts() {
      return this.headerSettings?.headerTexts
    },
    showCustomerSupportSidebar() {
      return !this.settings?.hideCustomerSupportSidebar
    },
    pageHeaderClasses() {
      return this.$el?.parentElement.querySelector('#main-content')?.className
    },
    isTransparent() {
      const headerClasses = this.$el?.parentElement.querySelector('#main-content')?.classList
      const hasTransparentClass = headerClasses?.contains('header-bgcolor--transparent')
      return hasTransparentClass
    },
    useHeaderHexColors() {
      return (this.isTransparent && this.isSticky)
    },
    defaultBackgroundColorHex() {
      return this.settings?.defaultHeaderBackgroundColorHex
    },
    backgroundColorStyle() {
      if (this.useHeaderHexColors) {
        const mainContentProperties = this.copyStyleProperties('#main-content')
        const headerBgColorHexProperty = mainContentProperties['--header-bgcolor-hex']
        if (headerBgColorHexProperty) {
          return { '--header-background': headerBgColorHexProperty }
        } else if (this.defaultBackgroundColorHex) {
          return { '--header-background': this.defaultBackgroundColorHex }
        }
      }
      return ''
    },
    defaultTextColor() {
      return this.settings?.defaultHeaderTextColor
    },
    defaultTextColorHex() {
      return this.settings?.defaultHeaderTextColorHex
    },
    pageTextColorHex() {
      const mainContentProperties = this.copyStyleProperties('#main-content')
      return mainContentProperties['--header-fgcolor-hex']
    },
    // only apply hex text color together with background hex
    textColorStyle() {
      if (this.useHeaderHexColors) {
          return { '--header-color': this.pageTextColorHex || this.defaultTextColorHex || this.defaultTextColor }
      }
      return ''
    },
  },
  mounted() {
    this.stickyHeaderListener()
    this.calcHeaderHeight()
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.stickyHeader)
  },
  methods: {
    linkColorCss(item) {
      return { '--link-color': color(item.linkColor, item.linkColorHex) }
    },
    stickyHeaderListener() {
      window.addEventListener('scroll', this.stickyHeader, {
        capture: true,
        passive: true,
      })
    },
    stickyHeader() {
      this.scrollpos = window.scrollY
      if (this.scrollpos >= this.offset) {
        this.isSticky = true
        this.setDefaultHeaderHeight()
      } else {
        this.isSticky = false
        this.setHeaderHeightWithTopbar()
      }
    },
    setDefaultHeaderHeight() {
      document.documentElement.style.setProperty(
        '--header-complete-height',
        'var(--header-height)'
      )
    },
    setHeaderHeightWithTopbar() {
      document.documentElement.style.setProperty(
        '--header-complete-height',
        'calc(var(--header-height) + var(--topbar-height))'
      )
    },
    calcHeaderHeight() {
      if (this.topbarTexts) {
        this.setHeaderHeightWithTopbar()
      } else {
        this.setDefaultHeaderHeight()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
header {
  width: 100%;
  z-index: 12;
  position: relative;
  position: sticky;
  top: 0;
  overflow: visible;
  transition: transform 0.7s cubic-bezier(0.5, 0, 0, 1);

  // Default colors
  --header-background: #{$grey-lighter};
  --header-color: #{$black};
  --header-color-rgb: #{hexToRGB($black)};

  .search-results-wrapper {
    position: absolute;
    overflow-y: auto;
    max-height: 80vh;
    z-index: -1;
    width: 100%;
  }

  .main-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: var(--header-height);
    background: var(--header-bgcolor-hex, var(--header-background));
    padding: 0.8rem;
    width: 100%;
    position: relative;

    .left,
    .right {
      width: calc((100% - 8rem) / 2);
      display: flex;
      align-items: center;
      height: 100%;
    }

    .logo {
      svg {
        margin-top: 1px;
        display: block;
        height: 2.4rem;

        path {
          fill: var(--header-custom-color, var(--header-color));
        }
      }
    }

    .left {
      .toggle-menu {
        order: 1;
      }
      .search {
        order: 2;
      }
    }

    .right {
      justify-content: flex-end;
      position: relative;
    }
  }

  .logo {
    transition: all 0.2s ease-out;
    display: block;

    &:active {
      transform: translateY(2px);
    }
  }

  .mini-cart {
    display: none;
  }

  &.is-sticky.with-topbar {
    transform: translateY(-32px);
  }
}

// Tablet (Portrait)
@media screen and (min-width: $tablet) {
  header {
    .main-header {
      padding-left: 2.4rem;
      padding-right: 2.4rem;

      .left,
      .right {
        gap: spacing('xsmall');
      }

      .logo {
        svg {
          height: 2.4rem;
        }
      }
    }
  }
}

// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
  header {
    .main-header {
      padding-left: spacing('large');
      padding-right: spacing('large');

      .logo {
        svg {
          height: 3.2rem;
        }
      }
    }
  }
}

// Laptop
@media screen and (min-width: $laptop) {
  header {
    .main-header {
      .logo {
        svg {
          height: 3.7rem;
        }
      }
    }
  }
}
</style>
