import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _c2c190e6 = () => interopDefault(import('../src/pages/shared-content/products/_.vue' /* webpackChunkName: "" */))
const _6e7c693a = () => interopDefault(import('../src/pages/_context/checkout/index.vue' /* webpackChunkName: "pages/_context/checkout/index" */))
const _5c1e4560 = () => interopDefault(import('../src/pages/_context/club.vue' /* webpackChunkName: "pages/_context/club" */))
const _0a53b05a = () => interopDefault(import('../src/pages/_context/club/index.vue' /* webpackChunkName: "pages/_context/club/index" */))
const _7f26a524 = () => interopDefault(import('../src/pages/_context/club/account.vue' /* webpackChunkName: "pages/_context/club/account" */))
const _2430e971 = () => interopDefault(import('../src/pages/_context/club/account/index.vue' /* webpackChunkName: "pages/_context/club/account/index" */))
const _662534d6 = () => interopDefault(import('../src/pages/_context/club/account/orders.vue' /* webpackChunkName: "pages/_context/club/account/orders" */))
const _f29f7a4e = () => interopDefault(import('../src/pages/_context/club/account/orders/index.vue' /* webpackChunkName: "pages/_context/club/account/orders/index" */))
const _19f0a2da = () => interopDefault(import('../src/pages/_context/club/account/orders/_orderId.vue' /* webpackChunkName: "pages/_context/club/account/orders/_orderId" */))
const _77046a2c = () => interopDefault(import('../src/pages/_context/club/login.vue' /* webpackChunkName: "pages/_context/club/login" */))
const _ae1d249c = () => interopDefault(import('../src/pages/_context/club/register.vue' /* webpackChunkName: "pages/_context/club/register" */))
const _5bc20828 = () => interopDefault(import('../src/pages/_context/club/reset-password.vue' /* webpackChunkName: "pages/_context/club/reset-password" */))
const _03799a5c = () => interopDefault(import('../src/pages/_context/checkout/result.vue' /* webpackChunkName: "pages/_context/checkout/result" */))
const _741e738c = () => interopDefault(import('../src/pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/:context?/shared-content/products/*",
    component: _c2c190e6,
    name: "shared-content-products-all-lang"
  }, {
    path: "/shared-content/products/*",
    component: _c2c190e6,
    name: "shared-content-products-all"
  }, {
    path: "/:context?/checkout",
    component: _6e7c693a,
    name: "context-checkout"
  }, {
    path: "/:context?/club",
    component: _5c1e4560,
    children: [{
      path: "",
      component: _0a53b05a,
      name: "context-club"
    }, {
      path: "account",
      component: _7f26a524,
      children: [{
        path: "",
        component: _2430e971,
        name: "context-club-account"
      }, {
        path: "orders",
        component: _662534d6,
        children: [{
          path: "",
          component: _f29f7a4e,
          name: "context-club-account-orders"
        }, {
          path: ":orderId",
          component: _19f0a2da,
          name: "context-club-account-orders-orderId"
        }]
      }]
    }, {
      path: "login",
      component: _77046a2c,
      name: "context-club-login"
    }, {
      path: "register",
      component: _ae1d249c,
      name: "context-club-register"
    }, {
      path: "reset-password",
      component: _5bc20828,
      name: "context-club-reset-password"
    }]
  }, {
    path: "/:context?/checkout/result",
    component: _03799a5c,
    name: "context-checkout-result"
  }, {
    path: "/*",
    component: _741e738c,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
