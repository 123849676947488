export default {
  state () {
    return {
      cartSidebarOpen: false
    }
  },
  mutations: {
    showCartSidebar (state) {
      state.cartSidebarOpen = true
    },
    hideCartSidebar (state) {
      state.cartSidebarOpen = false
    }
  },
  actions: {
    showCartSidebar ({ dispatch, commit }) {
      dispatch('ui/close-all/closeAll', null, { root: true })
      dispatch('ui/overlay/showOverlay', 90, { root: true })
      commit('showCartSidebar')
    },
    hideCartSidebar ({ dispatch, commit }) {
      dispatch('ui/overlay/hideOverlay', null, { root: true })
      commit('hideCartSidebar')
    }
  }
}
