const sysend = {
  on: () => {},
  off: () => {},
  broadcast: () => {},
  emit: () => {},
  proxy: () => {},
  serializer: () => {}
}

export default ({ app }, inject) => {
  inject('sysend', sysend)
}
