<template>
  <div
    class="before-after-block"
    :class="{
      'force-mobile-layout': forceMobileLayout,
      [`mp--${mobilePadding}`]: !!mobilePadding,
      [`mm--${mobileMargin}`]: !!mobileMargin,
      [`dp--${desktopPadding}`]: !!desktopPadding,
      [`dm--${desktopMargin}`]: !!desktopMargin,
      [`di ${desktopIndent}`]: !!desktopIndent,
    }"
    :style="[imagePositionCss, ctaAlignCss]"
  >
    <Headline
      v-if="displayHeadline"
      :key="'headline'"
      :headline-type="headline.headlineType"
      :headline-size="headline.headlineSize"
      :headline-color="headline.headlineColor"
      :headline-accent-color="headline.headlineAccentColor"
      :eyebrow="headline.eyebrow"
      :headline="headline.headline"
      :lead="headline.lead"
      :align-headline="headline.alignHeadline"
      :vertical-margin="headline.verticalMargin"
    />

    <div ref="imageWrapper" class="before-after-block__image-wrapper">
      <div
        class="is-before-image"
        :class="
          forceMobileLayout
            ? 'mobile-format--square'
            : 'mobile-format--square desktop-format--wide'
        "
      >
        <ResponsiveImage
          :lazyload="true"
          :mobile-src="beforeImage && beforeImage.filename"
          :alt="beforeImage ? beforeImage.alt : ''"
          :mobile-display-size="'100vw'"
          :desktop-display-size="'100vw'"
        />
      </div>

      <!-- Mobile after image -->
      <div
        class="before-after-block__reveal"
        :class="[
          revealAnimateOnEntryMobile,
          disableTransitionsClass,
          forceMobileLayout ? 'force-mobile' : 'show-on-mobile',
        ]"
        :style="revealClipPathMobile()"
      >
        <div
          class="is-after-image mobile-format--square desktop-format--square"
          :class="disableTransitionsClass"
          :style="afterImageClipPathMobile()"
        >
          <ResponsiveImage
            :lazyload="true"
            :mobile-src="afterImage && afterImage.filename"
            :alt="afterImage ? afterImage.alt : ''"
            :mobile-display-size="'100vw'"
            :desktop-display-size="'100vw'"
          />
        </div>
      </div>

      <!-- Desktop after image -->
      <div
        ref="imageWrapperSlider"
        class="before-after-block__image-wrapper__slider"
        :class="forceMobileLayout ? 'hide' : 'show-on-desktop'"
        :style="sliderPosition"
      >
        <IconChevronLeftLarge
          class="before-after-block__image__wrapper__slider__icon"
        />
        <IconChevronRightLarge
          class="before-after-block__image__wrapper__slider__icon"
        />
      </div>
      <div
        class="before-after-block__reveal"
        :class="forceMobileLayout ? 'force-mobile hide' : 'show-on-desktop'"
        :style="revealClipPathDesktop()"
      >
        <div
          class="is-after-image desktop-format--wide"
          :style="afterImageClipPathDesktop()"
        >
          <ResponsiveImage
            :lazyload="true"
            :mobile-src="afterImage && afterImage.filename"
            :alt="afterImage ? afterImage.alt : ''"
            :mobile-display-size="'100vw'"
            :desktop-display-size="'100vw'"
          />
        </div>
      </div>
      <div
        class="before-after-block__review-wrapper"
        :class="forceMobileLayout ? 'hide' : 'hide-on-mobile'"
      >
        <div
          v-if="!!reviewImage && !!reviewText && !!reviewAuthor"
          class="before-after-block__review"
        >
          <div class="before-after-block__review__profile">
            <div class="before-after-block__review__profile-qoute-sign">
              <span>&#8221;</span>
            </div>
            <div
              class="before-after-block__review__profile-picture desktop-format--square"
            >
              <ResponsiveImage
                :lazyload="true"
                :desktop-src="reviewImage && reviewImage.filename"
                alt=""
                :mobile-display-size="'10vw'"
                :desktop-display-size="'10vw'"
              />
            </div>
          </div>
          <p class="before-after-block__review__text">
            "{{ reviewText }}"
            <b class="author"> - {{ reviewAuthor }} </b>
          </p>
        </div>
      </div>
    </div>
    <div
      class="before-after-block__content grid"
      :class="forceMobileLayout ? 'mc--1' : 'dc--2 mc--1'"
    >
      <h6 class="before-after-block__content__name">
        {{ productName }}
        <mp-link
          v-if="!!productLink"
          :to="$u(productLink)"
          class="before-after-block__content__link"
        >
          {{ $t('Before_After_Product_Link') }}
        </mp-link>
      </h6>
      <p class="before-after-block__content__description">
        {{ productDescription }}
      </p>
    </div>
    <div
      class="before-after-block__cta"
      :class="{
        'hide-on-mobile': mobileHideCta,
        'hide-on-desktop': desktopHideCta,
      }"
    >
      <Button
        v-for="(cta, i) in ctas"
        :key="`before-after-block__cta__button${_uid + i}`"
        class="before-after-block__cta__button"
        v-bind="cta"
      />
    </div>
  </div>
</template>
<script>
import IconChevronLeftLarge from '@/assets/icons/chevron-left-large.svg'
import IconChevronRightLarge from '@/assets/icons/chevron-right-large.svg'

import IntersectionObserver from '@/mixins/intersectionObserver.js'

export default {
  name: 'BeforeAfterBlock',
  components: {
    IconChevronLeftLarge,
    IconChevronRightLarge,
  },
  mixins: [IntersectionObserver],
  props: {
    headlines: {
      type: Object,
      default: () => {},
    },
    displayHeadline: {
      type: Boolean,
      default: false,
    },
    beforeImage: {
      type: Object,
      required: false,
      default: null,
    },
    afterImage: {
      type: Object,
      required: false,
      default: null,
    },
    imagePosition: {
      type: String,
      required: false,
      default: '',
    },
    productName: {
      type: String,
      default: '',
    },
    productDescription: {
      type: String,
      default: '',
    },
    centraProductId: {
      type: String,
      default: '',
    },
    mobilePadding: {
      type: String,
      required: false,
      default: '',
    },
    mobileMargin: {
      type: String,
      required: false,
      default: '',
    },
    desktopPadding: {
      type: String,
      required: false,
      default: '',
    },
    desktopMargin: {
      type: String,
      required: false,
      default: '',
    },
    desktopIndent: {
      type: String,
      required: false,
      default: '',
    },
    reviewImage: {
      type: Object,
      required: false,
      default: () => {},
    },
    reviewAuthor: {
      type: String,
      required: false,
      default: '',
    },
    reviewText: {
      type: String,
      required: false,
      default: '',
    },
    forceMobileLayout: {
      type: Boolean,
      default: false,
    },
    ctas: {
      type: Array,
      default: () => [],
    },
    alignCta: {
      type: String,
      default: 'flex-start',
    },
    mobileHideCta: {
      type: Boolean,
      required: false,
      default: false,
    },
    desktopHideCta: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      headline: {
        headlineType: 'h2',
        headlineSize: 'medium',
        headlineColor: 'blue-dark',
        headlineAccentColor: undefined,
        eyebrow: undefined,
        headline: { html: 'Before / After' },
        lead: undefined,
        alignHeadline: undefined,
        verticalMargin: undefined,
      },
      productLink: null,
      isMounted: false,
      clipCordX: 0,
      clipCordY: 0,
      pressCord: { x: 0, y: 0 },
      clipCordDesktop: 50,
      moveClipMobile: false,
      moveClipDesktop: false,
      animate: false,
      disableTransitions: false,
      updateImage: 0,
    }
  },
  computed: {
    sliderPosition() {
      return { left: `${this.clipCordDesktop}px` }
    },
    revealAnimateOnEntryMobile() {
      if (this.animate) return 'reveal-animate-on-enter-mobile'
      return ''
    },
    disableTransitionsClass() {
      if (this.disableTransitions) return 'disable-transitions'
      return ''
    },
    imagePositionCss() {
      if (this.imagePosition) return { '--image-position': this.imagePosition }
      return {}
    },
    ctaAlignCss() {
      return { '--align-cta': this.alignCta }
    },
  },
  watch: {
    centraProductId: {
      immediate: true,
      handler(newVal, oldVal) {
        if (!!newVal && newVal !== oldVal) {
          this.$backendApi
            .get(`products/url-by-id/${newVal}`)
            .then((res) => {
              if (res.status === 200) {
                this.productLink = res.data
              }
            })
            .catch((err) => {
              if (err?.response?.status !== 404) {
                console.error(err)
              }
            })
        }
      },
    },
  },
  mounted() {
    this.isMounted = true
    this.observeElement(this.$el, this.animateOnEnter)
    this.clipCordDesktop = this.imageWrapperWidth() / 2

    this.$refs.imageWrapper.addEventListener(
      'pointerdown',
      this.onMouseDownImageWrapper
    )
    this.$refs.imageWrapper.addEventListener(
      'pointermove',
      this.onMouseMoveImageWrapper
    )
    this.$refs.imageWrapper.addEventListener(
      'pointerup',
      this.onMousUpImageWrapper
    )

    this.$refs.imageWrapperSlider.addEventListener(
      'mousedown',
      this.onMouseDownImageWrapperSlider
    )
  },
  methods: {
    animateOnEnter() {
      this.animate = true
    },
    onMouseDownImageWrapper(e) {
      this.disableTransitions = true
      this.moveClipMobile = true

      // save the coord for where the user press down
      this.pressCord.x =
        e.clientX - this.imageWrapperOffsetLeft() - this.clipCordX / 2
      this.pressCord.y =
        e.pageY - this.imageWrapperOffsetTop() - this.clipCordY / 2
    },
    onMousUpImageWrapper() {
      this.moveClipMobile = false
      this.moveClipDesktop = false
    },
    onMouseDownImageWrapperSlider() {
      this.moveClipDesktop = true
    },
    onMouseMoveImageWrapper(e) {
      const x = e.clientX - this.imageWrapperOffsetLeft()
      const y = e.pageY - this.imageWrapperOffsetTop()

      if (this.moveClipMobile) {
        this.clipCordX = (x - this.pressCord.x) * 2
        this.clipCordY = (y - this.pressCord.y) * 2
      }
      if (this.moveClipDesktop) this.clipCordDesktop = x
    },
    revealClipPathMobile() {
      if (this.animate) {
        return {
          'clip-path': `polygon(
            ${this.imageWrapperWidth() * 2}px
            ${-this.imageWrapperHeight() + this.clipCordY - 5}px,
             100% 100%,
            ${-this.imageWrapperWidth() + this.clipCordX - 5}px
            ${this.imageWrapperHeight() * 2}px
          )`,
        }
      }
    },
    afterImageClipPathMobile() {
      if (this.animate) {
        return {
          'clip-path': `polygon(
          ${this.imageWrapperWidth() * 2}px
          ${-this.imageWrapperHeight() + this.clipCordY}px,
           100% 100%,
          ${-this.imageWrapperWidth() + this.clipCordX}px
          ${this.imageWrapperHeight() * 2}px
        )`,
        }
      }
    },
    revealClipPathDesktop() {
      return {
        'clip-path': `polygon(
          ${this.clipCordDesktop - 2}px
          0, 100% 0%, 100% 100%,
        ${this.clipCordDesktop - 2}px 100%)`,
      }
    },
    afterImageClipPathDesktop() {
      return {
        'clip-path': `polygon(
          ${this.clipCordDesktop}px
          0, 100% 0%, 100% 100%,
        ${this.clipCordDesktop}px 100%)`,
      }
    },
    imageWrapperWidth() {
      if (this.isMounted) return this.$refs.imageWrapper.clientWidth
      return 0
    },
    imageWrapperHeight() {
      if (this.isMounted) return this.$refs.imageWrapper.clientHeight
      return 0
    },
    imageWrapperOffsetLeft() {
      if (this.isMounted) return this.$refs.imageWrapper.offsetLeft
      return 0
    },
    imageWrapperOffsetTop() {
      if (this.isMounted) return this.$refs.imageWrapper.offsetTop
      return 0
    },
    middlePoint() {
      return {
        x: this.imageWrapperWidth() / 2,
        y: this.imageWrapperHeight() / 2,
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.before-after-block {
  .before-after-block__image-wrapper {
    position: relative;
    display: grid;
    touch-action: none;
    overflow: hidden;
    cursor: grab;

    &:active {
      cursor: grabbing;
    }

    .is-before-image,
    .before-after-block__reveal {
      grid-row: 1 / -1;
      grid-column: 1 / -1;
    }

    .before-after-block__reveal {
      position: relative;
      width: 100%;
      height: 100%;
      background-color: $white;

      &.show-on-desktop {
        clip-path: polygon(50% 0, 100% 0%, 100% 100%, 50% 100%);
      }
    }

    .is-before-image,
    .is-after-image {
      user-select: none;

      ::v-deep {
        img {
          pointer-events: none;
        }
      }
    }

    .is-after-image {
      position: absolute;
      width: 100%;
      height: 100%;

      ::v-deep {
        img {
          object-position: var(--image-position);
        }
      }
    }

    .before-after-block__reveal.show-on-mobile,
    .before-after-block__reveal.force-mobile,
    .is-after-image {
      clip-path: polygon(100% 100%, 100% 100%, 100% 100%);
    }

    .before-after-block__reveal.force-mobile,
    .before-after-block__reveal.show-on-mobile,
    .show-on-mobile .is-after-image,
    .force-mobile .is-after-image {
      transition: clip-path 0.6s cubic-bezier(0.33, 0.33, 0, 1);

      &.disable-transitions {
        transition: none;
      }
    }
  }

  .before-after-block__image-wrapper__slider {
    @include caps('small');
    $size: 7rem;

    position: absolute;
    top: calc(50% - (#{$size} / 2));
    left: calc(50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: $size;
    height: $size;
    margin-left: calc(-#{$size} / 2);
    border-radius: 100%;
    background-color: $white;
    z-index: 2;
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }

  .before-after-block__image__wrapper__slider__icon {
    width: 1.2rem;
    height: 1.2rem;
  }

  .before-after-block__content {
    margin-top: spacing('xsmall');
  }

  .before-after-block__content__name {
    @include label('large');

    display: flex;
    justify-content: space-between;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: bold;
    text-transform: capitalize;
  }
  .before-after-block__content__link {
    color: var(--blue);
  }

  .before-after-block__content__description {
    @include p--medium;

    margin-top: 0.8rem;
  }

  .before-after-block__review-wrapper {
    width: 100%;
    position: absolute;
    bottom: spacing('small');
    display: flex;
    justify-content: center;
  }

  .before-after-block__review {
    display: inline-flex;
    align-items: center;
    width: 60%;
    padding: spacing('xxsmall');
    background-color: $white;
    border-radius: 4rem;
  }

  .before-after-block__review__profile {
    display: flex;
    align-items: center;
    margin-right: spacing('xsmall');
  }

  $icon-size: 4.4rem;

  .before-after-block__review__profile-qoute-sign {
    --qoute-sign-color: var(--white);
    --qoute-sign-bg-color: var(--blue);

    box-sizing: content-box;
    display: flex;
    justify-content: center;
    align-items: center;
    width: $icon-size;
    height: $icon-size;
    border-radius: 100%;
    font-family: 'Glorify';
    font-size: 6.8rem;
    margin-right: -0.5rem;
    color: var(--qoute-sign-color);
    background-color: var(--qoute-sign-bg-color);
    border: 0.2rem solid $white;
    z-index: 1;

    > span {
      margin-top: 4rem;
    }
  }

  .before-after-block__review__profile-picture {
    border-radius: 100%;
    width: $icon-size;
    height: $icon-size;
  }

  .before-after-block__review__text {
    margin-top: 0;
    margin-bottom: 0;
  }

  .before-after-block__cta {
    width: 100%;
    display: flex;
    justify-content: var(--align-cta);
    padding-top: spacing('xsmall');
  }
}
@media screen and (min-width: $tablet-landscape) {
  .before-after-block:not(.force-mobile-layout) {
    .before-after-block__content__name {
      justify-content: flex-start;
    }
    .before-after-block__content__link {
      margin-left: spacing('small');
    }
    .before-after-block__content__description {
      margin-top: 0;
      text-align: right;
    }
  }
}
</style>
