<template>
  <div class="toggle-help" title="Open help sidebar" @click="showMobileSidebar">
    <div class="icon">
      <HelpIcon v-show="!sidebarOpen" />
      <CrossIcon v-show="sidebarOpen" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import HelpIcon from '~/static/images/headset.svg'
import CrossIcon from '~/static/images/cross.svg'

export default {
  name: 'ToggleHelp',
  components: { HelpIcon, CrossIcon },
  computed: {
    ...mapState('ui/customer-support', ['sidebarOpen']),
  },
  methods: {
    showMobileSidebar() {
      if (this.sidebarOpen)
        this.$store.dispatch('ui/customer-support/hideSidebar')
      else this.$store.dispatch('ui/customer-support/showSidebar')
    },
  },
}
</script>

<style lang="scss" scoped>
.toggle-help {
  height: 4rem;
  width: 3.8rem;
  display: flex;
  justify-content: center;
  align-items: center;

  .icon {
    background-color: transparent;
    border: 0;
    padding: 0;
    width: 17px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 100%;
      height: 100%;
      fill: var(--header-custom-color, var(--header-color));
    }
  }
}

// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
  .toggle-help {
    height: auto;
    width: auto;
    cursor: pointer;

    .icon {
      width: 23px;
    }
  }
}

// Laptop
@media screen and (min-width: $laptop) {
  // ...
}
</style>
