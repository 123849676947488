import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      account: (state) => state.account.account,
    }),
    isLoggedIn() {
      return this.account && this.account.isLoggedIn
    },
    clubRoute() {
      return this.$route.path.split('club').pop().replace('/', '')
    },
  },
  methods: {
    redirectIfLoggedOut() {
      if (this.account !== null && !this.account.isLoggedIn) {
        this.$router.push(this.$u('club/login'))
      }
    },
    logout() {
      this.$backendApi
        .post('account/logout')
        .then(() => {
          this.$store.dispatch('account/getAccount')
          window.location.href = this.$u('club')
        })
        .catch(() => {
          this.$notify({
            title: this.$t('Account_Logout_Generic_Error'),
            text: this.$t('Account_Logout_Generic_ErrorMsg'),
            type: 'error',
          })
        })
    },
  },
}
