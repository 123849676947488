import { mapState } from 'vuex'

/**
 * Inits widgets on mounted + supplies script tag to be inserted into head()
 * Insert into head from mixin apparently gets overridden by component head() function
 */
export default {
  computed: {
    ...mapState({
      yotpoSettings: (state) => state.yotpo.settings,
    }),
    yotpoHeadScript() {
      return this.yotpoSettings.loyaltyEnabled
        ? {
            type: 'text/javascript',
            src: `https://cdn-widgetsrepository.yotpo.com/v1/loader/${this.yotpoSettings.loyaltyGUID}`,
            async: true,
          }
        : {}
    },
  },
  methods: {
    initYotpoWidgets() {
      window?.yotpoWidgetsContainer?.initWidgets &&
        window?.yotpoWidgetsContainer?.initWidgets()
    },
  },
}
