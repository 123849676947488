export default {
  state: () => ({
    blogPosts: [],
    totalPostCount: [],
  }),
  mutations: {
    blogPosts(state, { listId, posts, totalPostCount }) {
      // state.blogPosts = state.blogPosts.concat(posts.filter(x => state.blogPosts.find(y => y.id !== x.id)))
      state.blogPosts = state.blogPosts
        .filter((x) => x.listId !== listId)
        .concat(
          posts.map((post) => ({
            ...post.content,
            uuid: post.uuid,
            full_slug: post.full_slug,
            listId,
          }))
        )
      state.totalPostCount = state.totalPostCount
        .filter((x) => x.listId !== listId)
        .concat({ listId, totalPostCount })
    },
    blogPostsLoadMore(state, { listId, posts, totalPostCount }) {
      // state.blogPosts = state.blogPosts.concat(posts.filter(x => state.blogPosts.find(y => y.id !== x.id)))
      state.blogPosts = state.blogPosts.concat(
        posts.map((post) => ({
          ...post.content,
          uuid: post.uuid,
          full_slug: post.full_slug,
          listId,
        }))
      )
      state.totalPostCount = state.totalPostCount
        .filter((x) => x.listId !== listId)
        .concat({ listId, totalPostCount })
    },
  },
  actions: {
    /**
     * Fetches all stories with component BlogPost', optionally filtering by category and
     * skip the bloglist itself.
     * fetches a limited number based on the "limit" field specified in storyblok configuration, if it is unset or over 100, the limit will be 100
     */
    fetchBlogPostsByCategories(
      { commit, rootState },
      { categories, limit, page, includeIndex, listId }
    ) {
      const params = {
        starts_with: `${rootState.frontend.currentLanguageCode}/site/`,
      }

      // prepared for pagination when frontend is developed
      let perPage = parseInt(limit)
      if (perPage > 100 || perPage < 1) {
        perPage = 100
      }

      params.per_page = perPage
      if (page) {
        params.page = page
      }

      // Removes index page
      if (!includeIndex) {
        params.is_startpage = 0
      }

      // filter on category if any category is selected
      let categoryFilter = {}

      if (categories.length > 0) {
        categoryFilter = {
          postType: {
            in: categories.join(),
          },
        }
      }

      // filter on showForCountries, we want all blogs with '_all_countries' checked or the country we are viewing from checked
      const countryFilter = {
        showForCountries: {
          in_array: `_all_countries,${rootState.frontend.currentCountryCode}`,
        },
      }

      // filter on component === blogpost to only fetch blogPosts
      const componentFilter = {
        component: { in: 'BlogPost' },
      }

      params.filter_query = {
        ...componentFilter,
        ...categoryFilter,
        ...countryFilter,
      }
      // sort results by created date, storyblok seems to behave wierd with unpublished stories, they have created date after published date, but published stories seem
      // behave as excpected
      params.sort_by = 'created_at:desc'

      return this.$storyapi
        .get('cdn/stories', { params })
        .then((response) => {
          if (params.page) {
            commit('blogPostsLoadMore', {
              listId,
              posts: response.data.stories,
              totalPostCount: response.headers.total,
            })
          } else {
            commit('blogPosts', {
              listId,
              posts: response.data.stories,
              totalPostCount: response.headers.total,
            })
          }
        })
        .catch((error) => {
          console.error('Could not fetch blog posts from Storyblok: ', error)
        })
    },

    fetchBlogPostsByUuids({ commit, rootState }, { uuids, listId }) {
      const params = {
        by_uuids_ordered: uuids.join(),
      }

      // get content translated to current language
      params.starts_with = `${rootState.frontend.currentLanguageCode}/*`

      // filter on showForCountries, we want all blogs with '_all_countries' checked or the country we are viewing from checked
      params.filter_query = {
        showForCountries: {
          in_array: `_all_countries,${rootState.frontend.currentCountryCode}`,
        },
      }

      return this.$storyapi
        .get('cdn/stories', { params })
        .then((response) => {
          commit('blogPosts', { listId, posts: response.data.stories })
        })
        .catch((error) => {
          console.error('Could not fetch blog posts from Storyblok: ', error)
        })
    },
  },

  getters: {
    getBlogPostsByListId: (state) => (listId) =>
      state.blogPosts.filter((x) => x.listId === listId),
    getTotalPostCountByListId: (state) => (listId) =>
      state.totalPostCount?.filter((x) => x.listId === listId)[0]
        ?.totalPostCount,
  },
}
