export default {
  data() {
    return {
      observer: null,
      root: this.document,
      intersectionHandlers: {}
    }
  },
  mounted(){
    this.initIntersectionObserver()
  },
  methods: {
    initIntersectionObserver() {
      if(this.observer === null) {
        this.observer = new IntersectionObserver(this.handleObserverIntersect, {
          root: this.root,
          threshold: 0.7,
        })
      }
    },
    observeElement(elm, intersectionHandler, unObserve = true){
      if(this.observer){
        this.observer.observe(elm)
        this.intersectionHandlers[elm.__vue__._uid] = {handler: intersectionHandler, unObserve}
      }
    },
    handleObserverIntersect(entries) {
      entries.forEach((element) => {
        if (element.isIntersecting) {
          this.runIntersectHandler(element.target.__vue__._uid, element.target)
        }
      })
    },
    runIntersectHandler(id, target){
      const entry = this.intersectionHandlers[id]
      
      entry.handler()
      if(entry.unObserve) this.observer.unobserve(target)
      
    }
  }
}
