<template>
  <div class="customer-support-sidebar">
    <div class="sidebar-body">
      <CloseMenu
        class="close-menu"
        :visible="sidebarOpen"
        :direction="'right'"
        @close="hideMobileSidebar"
      />
      <h3 v-if="menuTitle">
        {{ menuTitle }}
      </h3>
      <div class="menu">
        <mp-link
          v-for="(item, index) in menu"
          :key="index"
          :to="$u(item.link)"
          class="link"
          target="_blank"
        >
          {{ item.label }}
        </mp-link>
      </div>
      <div v-if="shippingInfo" class="shipping-info">
        <div v-html="shippingInfo.html" />
      </div>
      <div v-if="supportEmail || openingHours" class="customer-care">
        <h3>
          {{ supportTitle }}
        </h3>
        <div class="wrap">
          <!-- <div class="open-chat">
            {{ $t('Global_Open_Chat') }}
          </div> -->
          <div v-if="supportEmail" class="email">
            <a :href="`mailto:${supportEmail}`">
              {{ supportEmail }}
            </a>
          </div>
          <div v-if="openingHours" class="opening-hours">
            <div v-html="nl2br(openingHours.html)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CloseMenu from '~/components/close-menu/CloseMenu'

export default {
  name: 'CustomerSupportSidebar',
  components: {
    CloseMenu,
  },
  computed: {
    ...mapState('ui/customer-support', ['sidebarOpen']),
    menuTitle() {
      return this.$store.state.storyblok.stories.find(
        (x) => x.data?.content?.component === 'CountryInformation'
      )?.data?.content?.customerSupportMenuTitle
    },
    menu() {
      return this.$store.state.storyblok.stories.find(
        (x) => x.data?.content?.component === 'CountryInformation'
      )?.data?.content?.customerSupportMenu
    },
    shippingInfo() {
      return this.$store.state.storyblok.stories.find(
        (x) => x.data?.content?.component === 'CountryInformation'
      )?.data?.content?.customerSupportMenuText
    },
    openingHours() {
      return this.$store.state.storyblok.stories.find(
        (x) => x.data?.content?.component === 'CountryInformation'
      )?.data?.content?.customerSupportOpeningHours
    },
    supportTitle() {
      return this.$store.state.storyblok.stories.find(
        (x) => x.data?.content?.component === 'CountryInformation'
      )?.data?.content?.customerSupportTitle
    },
    supportEmail() {
      return this.$store.state.storyblok.stories.find(
        (x) => x.data?.content?.component === 'CountryInformation'
      )?.data?.content?.customerSupportEmail
    },
  },
  methods: {
    nl2br(str) {
      return str.replace(/(?:\r\n|\r|\n)/g, '<br />')
    },
    hideMobileSidebar() {
      this.$store.dispatch('ui/customer-support/hideSidebar')
    },
  },
}
</script>

<style lang="scss" scoped>
.customer-support-sidebar {
  width: calc(100% - 5.6rem);
  height: calc(var(--vh, 1vh) * 100);
  max-width: 40rem;
  background-color: $white;
  color: $black;
  overflow: hidden;
  --padding: 2.4rem;
  .sidebar-body {
    padding: var(--padding);
    position: absolute;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    @include hide-scrollbars;
    z-index: 1;
    left: 0;
    background: $white;
    transition: transform 0.6s cubic-bezier(0.5, 0, 0, 1);

    .close-menu {
      margin-bottom: var(--padding);
      margin-left: auto;
    }

    h3 {
      @include caps('large');
      color: $blue;
      margin-bottom: 1.6rem;
    }
    .menu {
      display: flex;
      flex-direction: column;
      padding: 0 1.6rem;
      margin-bottom: 3.2rem;
      .link {
        padding: 0.4rem 0;
        @include label('large');
      }
    }
    .shipping-info {
      margin-top: 3.2rem;
      padding: 0 1.6rem;
      @include p--medium;
    }
    .customer-care {
      margin-top: 8rem;
      .wrap {
        padding: 0 1.6rem;
        .open-chat,
        .email {
          padding: 0.4rem 0;
          @include label('large');
        }
        .opening-hours {
          margin-top: 3.2rem;
          @include p--medium;
        }
      }
    }
  }
}

// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
}
</style>
