export default {
  state() {
    return {
      product: false,
      size: false,
      open: false,
    }
  },
  mutations: {
    showAddedToCart(state, { addedProduct, addedProductSize }) {
      state.product = addedProduct
      state.size = addedProductSize
      state.open = true
    },
    hideAddedToCart(state) {
      state.open = false
      state.product = false
      state.size = false
    },
  },
  actions: {
    showAddedToCart(
      { dispatch, commit },
      { addedProduct, addedProductSize, desktop = false }
    ) {
      dispatch('ui/close-all/closeAll', null, { root: true })
      if (!desktop) dispatch('ui/overlay/showOverlay', 9, { root: true })
      commit('showAddedToCart', { addedProduct, addedProductSize })
      setTimeout(() => {
        dispatch('hideAddedToCart')
      }, 3500)
    },
    hideAddedToCart({ dispatch, commit }) {
      commit('hideAddedToCart')
      dispatch('ui/overlay/hideOverlay', null, { root: true })
    },
  },
}
