export default {
  state () {
    return {
      /**
       * The overlay is hidden if z-index is set to 0
       * Set the z-index by passing it as a variable when
       * dispatching showOverlay
       */
      overlayZIndex: 0
    }
  },
  mutations: {
    showOverlay (state, zIndex) {
      state.overlayZIndex = zIndex
    },
    hideOverlay (state) {
      state.overlayZIndex = 0
    }
  },
  actions: {
    showOverlay ({ commit }, zIndex) {
      commit('showOverlay', zIndex)
    },
    hideOverlay ({ commit }) {
      commit('hideOverlay')
    }
  }
}
