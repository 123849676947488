export default {
  state () {
    return {
      isEnabled: true,
      sidebarOpen: false,
      itemId: null,
      product: null,
    }
  },
  mutations: {
    show (state, {itemId, product}) {
      state.itemId = itemId
      state.product = product
      state.sidebarOpen = true
    },
    hide (state) {
      state.sidebarOpen = false
    }
  },
  actions: {
    show ({ dispatch, commit }, {itemId, product}) {
      dispatch('ui/close-all/closeAll', null, { root: true })
      dispatch('ui/overlay/showOverlay', 90, { root: true })
      commit('show', {itemId, product})
    },
    hide ({ dispatch, commit }) {
      dispatch('ui/overlay/hideOverlay', null, { root: true })
      commit('hide')
    }
  }
}
